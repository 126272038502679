import area from "@turf/area";
// eslint-disable-next-line import/no-unresolved
import { Feature } from "geojson";
import React, { useState } from "react";
import styled from "styled-components";

import {
  AREA_THRESHOLD,
  DEFAULT_TURBINE_CAPACITY,
  MAX_NUMBER_OF_TURBINES,
} from "../constants";
import { ApiState, SlgApiData, SlgForm } from "../types";
import Container from "./Container";
import DataGroup from "./DataGroup";
import SectionTitle from "./SectionTitle";
import StyledButton from "./StyledButton";
import StyledInput from "./StyledInput";

const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 6px 0 12px 0;
`;

const OptionValue = styled.span`
  padding-left: 4px;
`;

const SeedLayoutGeneratorForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 375px;
`;

const ProjectBoundaryDescription = styled.div`
  line-height: 1.4;
  border-radius: 3px;
  background-color: #23374b;
  padding: 8px;
  margin: 8px 0 18px;
`;

const LayoutGeneratorControl: React.FC<{
  loading: boolean;
  onStartAction: () => void;
  onResetAction: () => void;
  onRunAction: () => void;
  onSendEmailAction: () => void;
  drawMode: boolean;
  slgForm: SlgForm;
  setSlgForm: (slgForm: SlgForm) => void;
  slgApiState: ApiState;
  sendEmailApiState: ApiState;
  polygonDrawn: Feature | undefined;
  apiData: SlgApiData | undefined;
  useAdvancedHeuristics: boolean;
}> = ({
  loading,
  onStartAction,
  onResetAction,
  onRunAction,
  onSendEmailAction,
  drawMode,
  slgForm,
  setSlgForm,
  slgApiState,
  sendEmailApiState,
  polygonDrawn,
  apiData,
  useAdvancedHeuristics,
}) => {
  const [showEmailBox, setShowEmailBox] = useState<boolean>(false);

  let slgButton;
  switch (slgApiState) {
    case ApiState.READY:
    case ApiState.ERROR:
      slgButton = `Generate${
        useAdvancedHeuristics ? " (with advanced heuristics)" : ""
      }`;
      break;
    case ApiState.FINISHED:
      slgButton = `Rerun${
        useAdvancedHeuristics ? " (with advanced heuristics)" : ""
      }`;
      break;
    case ApiState.LOADING:
      slgButton = "Generating... This may take a while";
      break;
    default:
      throw new Error(`Do not know what text to show with ${slgApiState}`);
  }

  const canRunSlg = slgApiState !== ApiState.LOADING;

  let sendEmailButton;
  switch (sendEmailApiState) {
    case ApiState.READY:
    case ApiState.ERROR:
      sendEmailButton = "Send email";
      break;
    case ApiState.FINISHED:
      sendEmailButton = "Sent!";
      break;
    case ApiState.LOADING:
      sendEmailButton = "Sending...";
      break;
    default:
      throw new Error(`Do not know what text to show with ${slgApiState}`);
  }

  const polygonArea = polygonDrawn && area(polygonDrawn);

  const maxTurbineCapacityAllowed =
    (slgForm.setAdvancedSettings
      ? Number(slgForm.mw)
      : DEFAULT_TURBINE_CAPACITY) * MAX_NUMBER_OF_TURBINES;

  return (
    <Container minWidth="375px">
      <SectionTitle>Layout Generator</SectionTitle>
      {drawMode ? (
        <SeedLayoutGeneratorForm>
          <ProjectBoundaryDescription>
            {polygonDrawn === undefined
              ? "Draw a project boundary by clicking on the map."
              : null}
            {polygonArea !== undefined && [
              !useAdvancedHeuristics && polygonArea > AREA_THRESHOLD
                ? `Drawn boundary is too large: ${(polygonArea / 1e6).toFixed(
                    1
                  )} km². Max ${
                    AREA_THRESHOLD / 1e6
                  } km². Adjust by first clicking the drawn polygon once, then dragging its vertices.`
                : `Project boundary area: ${(polygonArea / 1e6).toFixed(
                    1
                  )} km².`,
            ]}
            {slgApiState === ApiState.ERROR ? (
              <>
                <br />
                Error: change some parameters and try again.
              </>
            ) : null}
            {sendEmailApiState === ApiState.ERROR ? (
              <>
                <br />
                Error sending email. Please try again.
              </>
            ) : null}
          </ProjectBoundaryDescription>
          <DataGroup>
            <label htmlFor="form-wind-farm-name">Wind farm name</label>
            <StyledInput
              value={slgForm.windFarmName}
              onChange={({ target: { value } }) =>
                setSlgForm({ ...slgForm, windFarmName: value })
              }
              type="text"
              name="form-wind-farm-name"
              id="form-wind-farm-name"
              disabled={!canRunSlg}
            />
          </DataGroup>
          <DataGroup>
            <label htmlFor="form-max-capacity">Max capacity (MW)</label>
            <StyledInput
              value={slgForm.maxCapacity}
              onChange={({ target: { value } }) =>
                setSlgForm({ ...slgForm, maxCapacity: value })
              }
              type="number"
              name="form-max-capacity"
              id="form-max-capacity"
              disabled={!canRunSlg}
            />
          </DataGroup>
          {useAdvancedHeuristics &&
            [
              ["superfuncA", "Superfunc A"],
              ["superfuncB", "Superfunc B"],
              ["superfuncC", "Superfunc C"],
              ["superfuncMultiplier", "Superfunc multiplier"],
              ["slopefuncDropSteepness", "Slopefunc drop steepness"],
              ["slopefuncHalfPoint", "Slopefunc half point"],
              ["proximityMultiplier", "Proximity multiplier"],
              ["proximityPower", "Proximity power"],
              [
                "wakeMaxDownwindDistancePerDiameter",
                "Wake max downwind distance per diameter",
              ],
              ["wakeK", "Wake K"],
              ["wakeCt", "Wake Ct"],
              ["elevationAreaFactor", "Elevation area factor"],
              ["elevationPowerFactor", "Elevation power factor"],
              ["elevationOverallFactor", "Elevation overall factor"],
            ].map(([key, label]) => (
              <DataGroup key={key}>
                <label htmlFor={`advanced-heuristics-${key}`}>{label}</label>
                <StyledInput
                  // @ts-ignore typescript not supported
                  value={slgForm[key]}
                  onChange={({ target: { value } }) =>
                    setSlgForm({ ...slgForm, [key]: value })
                  }
                  name={`advanced-${key}`}
                  id={`advanced-${key}`}
                  disabled={!canRunSlg}
                />
              </DataGroup>
            ))}
          <OptionLabel htmlFor="set-advanced-settings">
            <input
              id="set-advanced-settings"
              onChange={(event) =>
                setSlgForm({
                  ...slgForm,
                  setAdvancedSettings: event.target.checked,
                })
              }
              checked={slgForm.setAdvancedSettings}
              name="set-advanced-settings"
              type="checkbox"
              disabled={!canRunSlg}
            />
            <OptionValue>Set advanced settings</OptionValue>
          </OptionLabel>
          {slgForm.setAdvancedSettings &&
            [
              ["mw", "Turbine size [MW]"],
              ["diameter", "Rotor diameter [m]"],
              ["ellipseMajor", "Major spacing [xRD]"],
              ["ellipseMinor", "Minor spacing [xRD]"],
              ["ellipseRotation", "Wind direction [deg]"],
            ].map(([key, label]) => (
              <DataGroup key={key}>
                <label htmlFor={`advanced-${key}`}>{label}</label>
                <StyledInput
                  // @ts-ignore typescript not supported
                  value={slgForm[key]}
                  onChange={({ target: { value } }) =>
                    setSlgForm({ ...slgForm, [key]: value })
                  }
                  type="number"
                  name={`advanced-${key}`}
                  id={`advanced-${key}`}
                  disabled={!canRunSlg}
                />
              </DataGroup>
            ))}
          {!useAdvancedHeuristics &&
            Number(slgForm.maxCapacity) > maxTurbineCapacityAllowed &&
            (!slgForm.setAdvancedSettings || slgForm.mw !== "") &&
            `The max capacity of your layout is too big. It should be at most ${maxTurbineCapacityAllowed} MW.`}
          <StyledButton
            size="small"
            disabled={
              !canRunSlg ||
              polygonDrawn === undefined ||
              !polygonArea ||
              (!useAdvancedHeuristics &&
                (Number(slgForm.maxCapacity) > maxTurbineCapacityAllowed ||
                  polygonArea > AREA_THRESHOLD)) ||
              [
                "windFarmName",
                "maxCapacity",
                // @ts-ignore typescript not supported
              ].some((k) => slgForm[k] === "") ||
              (slgForm.setAdvancedSettings &&
                [
                  "mw",
                  "diameter",
                  "ellipseMajor",
                  "ellipseMinor",
                  "ellipseRotation",
                  // @ts-ignore typescript not supported
                ].some((k) => slgForm[k] === ""))
            }
            onClick={onRunAction}
          >
            {slgButton}
          </StyledButton>
          {apiData &&
            [
              ["Wind farm name", apiData.sentData.windFarmName],
              ["Turbine name", apiData.turbineName],
              ["Number of turbines", apiData.result.length],
              [
                "Total capacity",
                `${(apiData.result.length * apiData.turbineCapacity).toFixed(
                  1
                )} MW`,
              ],
              ["Mean wind speed", `${apiData.averageWindSpeed.toFixed(2)} m/s`],
              [
                "Predominant wind direction (North=0, CW)",
                `${apiData.ellipseRotation.toFixed(0)} degrees`,
              ],
              [
                "GROSS capacity factor",
                `${(apiData.capacityFactor * 100).toFixed(2)}%`,
              ],
              ["Constructability", "coming soon!"],
              ["Reachability", "coming soon!"],
              ["LCOE range", "coming soon!"],
              ["IRR range", "coming soon!"],
            ].map(([key, value]) => (
              <DataGroup key={key}>
                <div>{key}</div>
                <div>{value}</div>
              </DataGroup>
            ))}
          {apiData?.message !== "" ? apiData?.message : null}
          {slgApiState === ApiState.FINISHED && (
            <StyledButton
              size="small"
              onClick={() => setShowEmailBox(true)}
              disabled={showEmailBox}
            >
              Get KML
            </StyledButton>
          )}
          {showEmailBox && (
            <>
              <div>
                Please fill in your email. We will send you the KML file.
              </div>
              <DataGroup>
                <label htmlFor="form-email">Email</label>
                <StyledInput
                  value={slgForm.email}
                  onChange={({ target: { value } }) =>
                    setSlgForm({ ...slgForm, email: value })
                  }
                  type="email"
                  name="form-email"
                  id="form-email"
                />
              </DataGroup>
              <StyledButton
                size="small"
                onClick={onSendEmailAction}
                disabled={sendEmailApiState === ApiState.LOADING}
              >
                {sendEmailButton}
              </StyledButton>
            </>
          )}
          <StyledButton
            size="small"
            onClick={() => {
              setShowEmailBox(false);
              onResetAction();
            }}
            disabled={slgApiState === ApiState.LOADING}
          >
            Reset
          </StyledButton>
        </SeedLayoutGeneratorForm>
      ) : (
        <StyledButton size="small" onClick={onStartAction} disabled={loading}>
          Draw Boundary
        </StyledButton>
      )}
    </Container>
  );
};

export default LayoutGeneratorControl;

import MapBoxDraw from "@mapbox/mapbox-gl-draw";
// eslint-disable-next-line import/no-unresolved
import { Feature } from "geojson";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { stringify } from "wellknown";
import {
  getLspResultsByPolygonFid,
  runSeedLayoutGenerator,
  runSeedLayoutGeneratorWithAdvancedHeuristics,
  sendKmlResults,
} from "../api";
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_ZOOM,
  LAYERS_LEGEND_MAPPING,
  LAYER_TILESET_MAPPING,
  LSP_SOURCE_LAYERS,
  RASTER_LAYER_OPTIONS,
  VECTOR_LAYER_OPTIONS,
} from "../constants";
import {
  ApiState,
  LspResult,
  SlgApiData,
  SlgForm,
  initialSlgForm,
} from "../types";
import { addTurbinesToMap } from "../utils";
import Container from "./Container";

import { LineDrawStyle, PointDrawStyle, PolygonDrawStyle } from "./DrawStyles";
import LayersControl, { LayerOption } from "./LayersControl";

import LayersLegend, { LayerLegendConfig } from "./LayersLegend";
import LayoutGeneratorControl from "./LayoutGeneratorControl";
import LocationIndicator from "./LocationIndicator";
import LspInfo from "./LspInfo";
import LspResultShow from "./LspResultShow";
import LspResultViewer from "./LspResultViewer";
import { copyTextToClipboard } from "./MapBoxMapHelper";
import SlopeMask from "./SlopeMask";
import {
  getTilesetVisibility,
  toggleTilesetVisibility,
} from "./TilesetsHelper";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGhldGxpbnRodSIsImEiOiJjam4yd3Uyd3AwNjZyM2ttaHVxc2ZoNnJ6In0.cuZ1dRQEGiazjXDScNXgyg";

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
`;

const LeftPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  display: flex;
  align-items: flex-start;
`;

const DiscoverBox = styled.div<{ noMarginLeft?: boolean }>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? 0 : "12px")};

  & > * {
    width: 100%;
  }

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const MapBoxMap: React.FC<Record<string, never>> = () => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapBoxDrawRef = useRef<MapBoxDraw>(
    new MapBoxDraw({
      displayControlsDefault: false,
      defaultMode: "draw_polygon",
      styles: [...PointDrawStyle, ...LineDrawStyle, ...PolygonDrawStyle], // Requires all styles to be defined. Features will not render if styles are not defined
      modes: {
        // @ts-ignore only required key is toDisplayFeatures
        do_nothing: {
          toDisplayFeatures: (state, geojson, display) => display(geojson),
        },
        ...MapBoxDraw.modes,
      },
    })
  );
  const markerPointsRef = useRef<mapboxgl.Marker[]>([]);
  const ellipseSourceIdsRef = useRef<string[]>([]);

  const [activeVectorLayer, setActiveVectorLayer] = useState<LayerOption[]>(
    VECTOR_LAYER_OPTIONS.filter(
      (opt) =>
        getTilesetVisibility(LAYER_TILESET_MAPPING[opt.layerName]) === "visible"
    )
  );
  const [visibleLegendConfigs, setVisibleLegendConfigs] = useState<
    LayerLegendConfig[]
  >(
    activeVectorLayer
      .filter((opt) => opt.layerName in LAYERS_LEGEND_MAPPING)
      .map((opt) => LAYERS_LEGEND_MAPPING[opt.layerName])
  );
  const [drawMode, setDrawMode] = useState<boolean>(false);
  const [slgForm, setSlgForm] = useState<SlgForm>(initialSlgForm);
  const [slgApiState, setSlgApiState] = useState<ApiState>(ApiState.READY);
  const [sendEmailApiState, setSendEmailApiState] = useState<ApiState>(
    ApiState.READY
  );
  const [polygonDrawn, setPolygonDrawn] = useState<Feature | undefined>(
    undefined
  );
  const [apiData, setApiData] = useState<SlgApiData | undefined>(undefined);

  const [useAdvancedHeuristics, setUseAdvancedHeuristics] =
    useState<boolean>(false);
  useEffect(() => {
    const callback = (e: any) =>
      e.code === "Backquote" && setUseAdvancedHeuristics((h) => !h);
    document.addEventListener("keydown", callback);
    return () => document.removeEventListener("keydown", callback);
  }, []);

  const [lng, setLng] = useState(DEFAULT_LNG);
  const [lat, setLat] = useState(DEFAULT_LAT);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);

  const [lspPolygonId, setLspPolygonId] = useState<number | undefined>();

  const [loading, setLoading] = useState(true);
  const [lspSelectedData, setLspSelectedData] = useState<LspResult | undefined>(
    undefined
  );

  useEffect(() => {
    if (mapRef.current) return; // initialize mapRef only once
    if (!mapContainerRef.current) return;
    if (!mapboxgl.supported()) {
      alert("Your browser does not support Mapbox GL");
    }

    const mapBoxMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [DEFAULT_LNG, DEFAULT_LAT],
      zoom: DEFAULT_ZOOM,
      style: "mapbox://styles/thetlinthu/ckrbvwbsh09gh18qi150b5rj8",
      attributionControl: false,
    });

    mapBoxMap
      .addControl(
        new mapboxgl.AttributionControl({
          customAttribution:
            '© <a href="https://traverse.ai" target="_blank">Traverse Technologies</a>',
        })
      )
      .addControl(new mapboxgl.ScaleControl(), "top-right")
      .on("load", () => {
        setLoading(false);
        mapBoxMap
          .addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14,
          })
          .setTerrain(
            // add the DEM source as a terrain layer with exaggerated height
            { source: "mapbox-dem", exaggeration: 1.0 }
          )
          .loadImage("./turbine.png", (err, img) => {
            if (err) {
              throw err;
            }
            if (img === undefined) {
              return;
            }
            mapBoxMap.addImage("turbine", img);
          });

        for (const tilesetOption of Object.values(LAYER_TILESET_MAPPING)) {
          for (const [tilesetSourceName, tilesetSource] of Object.entries(
            tilesetOption.tilesetSources
          )) {
            if (!mapBoxMap.getSource(tilesetSourceName)) {
              mapBoxMap.addSource(tilesetSourceName, tilesetSource);

              for (const tilesetLayer of tilesetOption.tilesetLayers) {
                if (tilesetLayer.source === tilesetSourceName) {
                  mapBoxMap.addLayer(tilesetLayer);
                }
              }
            }
          }
        }
      });

    for (const sourceLayer of Object.keys(LSP_SOURCE_LAYERS)) {
      mapBoxMap.on("click", `lspFill-${sourceLayer}`, (e) => {
        // @ts-ignore cast didn't work
        setLspPolygonId(e.features?.[0]?.id);
      });
      mapBoxMap.on("click", `lspFill-contour-${sourceLayer}`, (e) => {
        // @ts-ignore cast didn't work
        setLspPolygonId(e.features?.[0]?.id);
      });
    }

    mapBoxMap
      .on("mousemove", ({ lngLat }) => {
        setLng(lngLat.lng);
        setLat(lngLat.lat);
      })
      .on("contextmenu", ({ lngLat }) => {
        try {
          copyTextToClipboard(`${lngLat.lng} ${lngLat.lat}`);
        } catch (err) {
          // This feature is for convenience. When it does not work, we want to avoid all errors.
        }
      })
      .on("zoom", () => {
        setZoom(mapBoxMap.getZoom());
      })
      .on("draw.modechange", ({ mode }) => {
        const numFeatures = mapBoxDrawRef.current.getAll().features.length;
        if (
          !(
            (numFeatures === 0 &&
              ["draw_polygon", "do_nothing"].includes(mode)) ||
            (["simple_select", "direct_select"].includes(mode) &&
              numFeatures === 1)
          )
        ) {
          // If the state of mapboxdraw is invalid we just reset the entire thing
          // and set draw mode to be false.
          // State is valid iff:
          // - mode is draw_polygon / do_nothing and there are 0 features in the map, or
          // - mode is simple_select / direct_select and there is 1 feature in the map.
          setDrawMode(false);
        }
      });

    const updateFeaturesDrawn = () => {
      setPolygonDrawn(mapBoxDrawRef.current.getAll().features[0]);
    };
    mapBoxMap
      .on("draw.create", updateFeaturesDrawn)
      .on("draw.delete", updateFeaturesDrawn)
      .on("draw.combine", updateFeaturesDrawn)
      .on("draw.uncombine", updateFeaturesDrawn)
      .on("draw.update", updateFeaturesDrawn);

    mapRef.current = mapBoxMap;
  }, []);

  useEffect(() => {
    if (
      drawMode &&
      mapRef.current &&
      !mapRef.current.hasControl(mapBoxDrawRef.current)
    ) {
      mapRef.current.addControl(mapBoxDrawRef.current);
    }
  }, [drawMode]);

  useEffect(() => {
    if (drawMode && slgApiState !== ApiState.READY) {
      // @ts-ignore custom mode
      mapBoxDrawRef.current?.changeMode("do_nothing");
    }
  }, [drawMode, slgApiState]);

  useEffect(() => {
    if (!mapRef.current || mapRef.current.getSource("lspFill") === undefined) {
      return;
    }

    for (const sourceLayer of Object.keys(LSP_SOURCE_LAYERS)) {
      mapRef.current.removeFeatureState({
        source: "lspFill",
        sourceLayer,
      });
      if (lspPolygonId !== undefined) {
        mapRef.current.setFeatureState(
          {
            id: lspPolygonId,
            source: "lspFill",
            sourceLayer,
          },
          { selected: true }
        );
      }
    }
  }, [lspPolygonId]);

  const lspCurrentPolygonMarkersRef = useRef<mapboxgl.Marker[]>([]);
  useEffect(() => {
    (async () => {
      for (const marker of lspCurrentPolygonMarkersRef.current) {
        marker.remove();
      }
      lspCurrentPolygonMarkersRef.current = [];

      if (lspPolygonId === undefined || !mapRef.current) {
        return;
      }

      setLspSelectedData(undefined);
      const lspResultsForPolygon = await getLspResultsByPolygonFid(
        lspPolygonId
      );
      setLspSelectedData(lspResultsForPolygon);

      lspCurrentPolygonMarkersRef.current = addTurbinesToMap(
        mapRef.current,
        lspResultsForPolygon.points.map(([x, y]) => ({ x, y })),
        undefined,
        true
      );
    })();
  }, [lspPolygonId]);

  const clearResults = useCallback(() => {
    if (!mapRef.current) return;

    for (const marker of markerPointsRef.current) {
      marker.remove();
    }
    markerPointsRef.current = [];

    for (const sourceId of ellipseSourceIdsRef.current) {
      mapRef.current.removeLayer(sourceId).removeSource(sourceId);
    }
    ellipseSourceIdsRef.current = [];

    setApiData(undefined);
  }, []);

  const clearState = useCallback(() => {
    if (!mapRef.current) return;

    clearResults();

    setSlgApiState(ApiState.READY);
    setSendEmailApiState(ApiState.READY);

    if (mapRef.current?.hasControl(mapBoxDrawRef.current)) {
      mapBoxDrawRef.current?.deleteAll();
      mapRef.current.removeControl(mapBoxDrawRef.current);
    }

    setSlgForm(initialSlgForm);
    setPolygonDrawn(undefined);
  }, [clearResults]);

  useEffect(() => {
    if (!drawMode) {
      clearState();
    }
  }, [drawMode, clearState]);

  const toggleLegendVisibility = (
    selectedOption?: LayerOption,
    unselectedOption?: LayerOption
  ) => {
    let nextVisibleLegendConfigs: LayerLegendConfig[] = visibleLegendConfigs;
    if (selectedOption && selectedOption.layerName in LAYERS_LEGEND_MAPPING) {
      nextVisibleLegendConfigs = [
        ...nextVisibleLegendConfigs,
        LAYERS_LEGEND_MAPPING[selectedOption.layerName],
      ];
    }
    if (
      unselectedOption &&
      unselectedOption.layerName in LAYERS_LEGEND_MAPPING
    ) {
      const legendConfig = LAYERS_LEGEND_MAPPING[unselectedOption.layerName];
      nextVisibleLegendConfigs = nextVisibleLegendConfigs.filter(
        (config) => config !== legendConfig
      );
    }
    setVisibleLegendConfigs(nextVisibleLegendConfigs);
  };

  const onSelectRaster = (
    selectedOption: LayerOption,
    unselectedOption?: LayerOption
  ) => {
    if (!mapRef.current) return;

    if (unselectedOption && unselectedOption.layerName !== "None") {
      toggleTilesetVisibility(
        mapRef.current,
        LAYER_TILESET_MAPPING[unselectedOption.layerName],
        "none"
      );
    }
    if (selectedOption.layerName !== "None") {
      toggleTilesetVisibility(
        mapRef.current,
        LAYER_TILESET_MAPPING[selectedOption.layerName],
        "visible"
      );
    }
    toggleLegendVisibility(selectedOption, unselectedOption);
  };

  const onToggleVector = (selectedOption: LayerOption, isCheck: boolean) => {
    if (!mapRef.current) return;

    let nextActiveVectorLayers: LayerOption[];
    if (isCheck) {
      toggleTilesetVisibility(
        mapRef.current,
        LAYER_TILESET_MAPPING[selectedOption.layerName],
        "visible"
      );
      nextActiveVectorLayers = [...activeVectorLayer, selectedOption];
      toggleLegendVisibility(selectedOption, undefined);
    } else {
      toggleTilesetVisibility(
        mapRef.current,
        LAYER_TILESET_MAPPING[selectedOption.layerName],
        "none"
      );
      nextActiveVectorLayers = activeVectorLayer.filter(
        (opt) => opt.layerName !== selectedOption.layerName
      );
      toggleLegendVisibility(undefined, selectedOption);
    }
    setActiveVectorLayer(nextActiveVectorLayers);
  };

  const onRunAction = async () => {
    if (mapRef.current === null || polygonDrawn === undefined) {
      return;
    }

    try {
      setSlgApiState(ApiState.LOADING);
      clearResults();
      // @ts-ignore stringify signature mistake
      const wkt = stringify(polygonDrawn);
      const result = await (useAdvancedHeuristics
        ? runSeedLayoutGeneratorWithAdvancedHeuristics(
            wkt,
            Number(slgForm.maxCapacity),
            slgForm.setAdvancedSettings,
            Number(slgForm.superfuncA),
            Number(slgForm.superfuncB),
            Number(slgForm.superfuncC),
            Number(slgForm.superfuncMultiplier),
            Number(slgForm.slopefuncDropSteepness),
            Number(slgForm.slopefuncHalfPoint),
            Number(slgForm.proximityMultiplier),
            Number(slgForm.proximityPower),
            Number(slgForm.wakeMaxDownwindDistancePerDiameter),
            Number(slgForm.wakeK),
            Number(slgForm.wakeCt),
            Number(slgForm.elevationAreaFactor),
            Number(slgForm.elevationPowerFactor),
            Number(slgForm.elevationOverallFactor),
            slgForm.setAdvancedSettings ? Number(slgForm.mw) : undefined,
            slgForm.setAdvancedSettings ? Number(slgForm.diameter) : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseMajor)
              : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseMinor)
              : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseRotation)
              : undefined
          )
        : runSeedLayoutGenerator(
            wkt,
            Number(slgForm.maxCapacity),
            slgForm.setAdvancedSettings,
            slgForm.setAdvancedSettings ? Number(slgForm.mw) : undefined,
            slgForm.setAdvancedSettings ? Number(slgForm.diameter) : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseMajor)
              : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseMinor)
              : undefined,
            slgForm.setAdvancedSettings
              ? Number(slgForm.ellipseRotation)
              : undefined
          ));

      markerPointsRef.current = markerPointsRef.current.concat(
        addTurbinesToMap(mapRef.current, result.points, {
          ellipseSourceIdNaming: (idx) => `ellipse-${idx}`,
          ellipseBaseDiameter: result.ellipse_base_diameter,
          ellipseMajor: result.ellipse_major,
          ellipseMinor: result.ellipse_minor,
          ellipseRotation: result.ellipse_rotation,
        })
      );
      ellipseSourceIdsRef.current = ellipseSourceIdsRef.current.concat(
        result.points.map((_, idx) => `ellipse-${idx}`)
      );
      setSlgApiState(ApiState.FINISHED);
      setApiData({
        sentData: { ...slgForm },
        sentPolygon: polygonDrawn,
        result: result.points,
        message: result.turbine_class_message,
        turbineName: result.turbine_name,
        turbineCapacity: result.turbine_capacity,
        ellipseBaseDiameter: result.ellipse_base_diameter,
        ellipseMajor: result.ellipse_major,
        ellipseMinor: result.ellipse_minor,
        ellipseRotation: result.ellipse_rotation,
        averageWindSpeed:
          result.points.reduce((acc, point) => acc + point.ws, 0) /
          result.points.length,
        capacityFactor: result.capacity_factor,
      });
    } catch (e) {
      console.error(e);
      setSlgApiState(ApiState.ERROR);
    }
  };

  const onSendEmailAction = async () => {
    if (mapRef.current === null || apiData === undefined) {
      return;
    }

    try {
      setSendEmailApiState(ApiState.LOADING);

      // @ts-ignore stringify signature mistake
      const wkt = stringify(apiData.sentPolygon);
      await sendKmlResults(
        wkt,
        slgForm.windFarmName,
        Number(slgForm.maxCapacity),
        slgForm.setAdvancedSettings,
        apiData.message,
        slgForm.email,
        apiData.result,
        apiData.turbineCapacity,
        apiData.ellipseBaseDiameter,
        apiData.ellipseMajor,
        apiData.ellipseMinor,
        apiData.ellipseRotation
      );
      setSendEmailApiState(ApiState.FINISHED);
    } catch (e) {
      console.error(e);
      setSendEmailApiState(ApiState.ERROR);
    }
  };

  return (
    <>
      <LocationIndicator lng={lng} lat={lat} zoom={zoom} />
      <LeftPanel>
        <DiscoverBox noMarginLeft>
          <LayersControl
            loading={loading}
            rasterOptions={RASTER_LAYER_OPTIONS}
            vectorOptions={VECTOR_LAYER_OPTIONS}
            initialRasterOptions={RASTER_LAYER_OPTIONS.filter(
              ({ layerName }) => layerName === "None"
            )}
            selectedVectorOptions={activeVectorLayer}
            onSelectRaster={onSelectRaster}
            onToggleVector={onToggleVector}
          />
        </DiscoverBox>
        <DiscoverBox>
          <LayoutGeneratorControl
            loading={loading}
            onStartAction={() => setDrawMode(true)}
            onRunAction={onRunAction}
            onResetAction={() => setDrawMode(false)}
            onSendEmailAction={onSendEmailAction}
            drawMode={drawMode}
            slgForm={slgForm}
            setSlgForm={setSlgForm}
            slgApiState={slgApiState}
            sendEmailApiState={sendEmailApiState}
            polygonDrawn={polygonDrawn}
            apiData={apiData}
            useAdvancedHeuristics={useAdvancedHeuristics}
          />
          {useAdvancedHeuristics && (
            <LspResultViewer mapBoxMap={mapRef.current} />
          )}
        </DiscoverBox>
        <DiscoverBox>
          <SlopeMask loading={loading} mapBoxMap={mapRef.current} />
        </DiscoverBox>
        <DiscoverBox>
          <LspResultShow mapBoxMap={mapRef.current} loading={loading} />
          <LspInfo
            lspPolygonId={lspPolygonId}
            setLspPolygonId={setLspPolygonId}
            lspSelectedData={lspSelectedData}
            mapBoxMap={mapRef.current}
          />
        </DiscoverBox>
        <DiscoverBox>
          <Container fontSize="16px" fontWeight="500">
            3D Mode: Right Click and Pan
          </Container>
          <Container
            fontSize="16px"
            fontWeight="500"
            style={{
              textAlign: "center",
              backgroundColor: "rgba(217, 4, 41, 0.9)",
            }}
          >
            <a
              href="https://www.traverse.ai/blog/winddesk-discover-prospect-for-a-wind-farm-straight-from-your-browser"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              Click Here for Tutorial
            </a>
          </Container>
        </DiscoverBox>
      </LeftPanel>
      <LayersLegend configs={visibleLegendConfigs} />
      <MapContainer ref={mapContainerRef} />
    </>
  );
};

export default MapBoxMap;

import { Button } from "antd";
import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  background-color: rgba(35, 55, 75);
  color: #ffffff;
  z-index: 1;
  width: 100vw;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  padding: 4px 12px;
  display: flex;
`;

const Brand = styled.div`
  font-size: 22px;
  margin-left: 8px;
  align-self: center;
  user-select: none;
  text-transform: uppercase;
  font-family: "Blender Pro Medium", "Barlow", sans-serif;
  font-weight: 400;
`;

const PoweredByAttributionContainer = styled.div`
  height: 29px;
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

// const PoweredByAttributionText = styled.div`
//   font-family: 'Blender Pro Medium', 'Barlow', sans-serif;
//   font-size: 10px;
//   text-align: center;
//   text-transform: uppercase;
//   user-select: none;
// `;

const Logo = styled.img`
  height: 18px;
`;

const RightContainer = styled.div`
  padding: 4px 12px;
  display: flex;
`;

const ContactUsButton = styled(Button)`
  align-self: center;
  margin-right: 18px;
  font-weight: 500;
  border: 0;
  width: 100px;

  &:hover {
    background-color: #0077d8;
    color: #fff;
    border: 0;
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <LeftContainer>
        <Brand>WindDesk Discover</Brand>
      </LeftContainer>
      <RightContainer>
        <ContactUsButton
          size="small"
          onClick={() =>
            window.open("https://www.traverse.ai/about-us/contact")
          }
        >
          Get in Touch
        </ContactUsButton>
        <PoweredByAttributionContainer>
          {/* <PoweredByAttributionText>A Project By</PoweredByAttributionText> */}
          <a href="https://traverse.ai" target="_blank" rel="noreferrer">
            <Logo src="/TraverseLogoWhite.svg" alt="Traverse" />
          </a>
        </PoweredByAttributionContainer>
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;

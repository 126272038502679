import mapboxgl, { Visibility } from "mapbox-gl";
import { TilesetOption } from "../tilesets";

export const getTilesetVisibility = (
  tilesetOption: TilesetOption
): Visibility => {
  for (const tilesetLayer of tilesetOption.tilesetLayers) {
    if (tilesetLayer.layout?.visibility === "visible") {
      return tilesetLayer.layout?.visibility;
    }
  }
  return "none";
};

export const toggleTilesetVisibility = (
  map: mapboxgl.Map,
  tilesetOption: TilesetOption,
  visibility: Visibility
): void => {
  for (const tilesetLayer of tilesetOption.tilesetLayers) {
    map.setLayoutProperty(tilesetLayer.id, "visibility", visibility);
  }
};

import styled from "styled-components";

const DataGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > * {
    flex: 1;
  }
`;

export default DataGroup;

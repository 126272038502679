import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin: 8px 0;
  font-weight: 500;
  height: 28px;
  width: 100%;
  align-self: center;
`;

export default StyledButton;

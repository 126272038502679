import styled from "styled-components";

type ContainerProps = {
  fontSize?: string;
  fontWeight?: string;
  minWidth?: string;
};

const Container = styled.div<ContainerProps>`
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 3px;
  width: fit-content;
  height: fit-content;
  font-size: ${({ fontSize }) => fontSize ?? "inherit"};
  font-weight: ${({ fontWeight }) => fontWeight ?? "inherit"};
  min-width: ${({ minWidth }) => minWidth ?? "inherit"};
`;

export default Container;

import { Input } from "antd";
import styled from "styled-components";

const StyledInput = styled(Input)`
  height: 24px;
  font-size: 14px;
  padding: 0 6px;
  font-weight: 500;
`;

export default StyledInput;

import React from "react";

import styled from "styled-components";
import Header from "./components/Header";
import MapBoxMap from "./components/MapBoxMap";

const Content = styled.div`
  position: relative;
  height: calc(100vh - 40px);
`;

const App: React.FC<Record<string, never>> = () => {
  return (
    <>
      <Header />
      <Content>
        <MapBoxMap />
      </Content>
    </>
  );
};

export default App;

import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { Tooltip } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { TilesetCategory, UiType } from "../types";
import Container from "./Container";

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const OptionValue = styled.span`
  margin-left: 6px;
  margin-right: 6px;
`;

const TooltipElement = styled.div`
  display: inline-block;
  margin-left: 2px;
`;

export interface LayerOption {
  name: string;
  layerName: string;
  tilesetCategory: TilesetCategory;
  uiType: UiType;
  uiGroupName: string;
  tooltip?: string;
}

const LayersControl: React.FC<{
  loading: boolean;
  rasterOptions: LayerOption[];
  vectorOptions: LayerOption[];
  initialRasterOptions: LayerOption[];
  selectedVectorOptions: LayerOption[];
  onSelectRaster: (
    selectedOption: LayerOption,
    unselectedOption?: LayerOption
  ) => void;
  onToggleVector: (option: LayerOption, isCheck: any) => void;
}> = ({
  loading,
  rasterOptions,
  vectorOptions,
  initialRasterOptions,
  selectedVectorOptions,
  onSelectRaster,
  onToggleVector,
}) => {
  const [selectedRasterOptions, setSelectedRasterOptions] = useState<
    Record<string, LayerOption>
  >(
    Object.fromEntries(
      initialRasterOptions.map((opt) => [opt.uiGroupName, opt])
    )
  );
  const selectedVectorOptionNames = selectedVectorOptions.map(
    (opt) => opt.layerName
  );

  const onToggleRasterRadioGroup = (option: LayerOption) => {
    const prevLayerOption = selectedRasterOptions[option.uiGroupName];
    onSelectRaster(option, prevLayerOption);
    setSelectedRasterOptions({
      ...selectedRasterOptions,
      [option.uiGroupName]: option,
    });
  };

  const renderTooltip = (option: LayerOption) => {
    return (
      <Tooltip title={option.tooltip} placement="right">
        <TooltipElement>
          <InfoCircleOutlined />
        </TooltipElement>
      </Tooltip>
    );
  };

  const renderRasterOption = (option: LayerOption, i: number) => (
    <div key={`${i}`}>
      <OptionLabel htmlFor={`${option.name}_${i}`} key={i}>
        <input
          id={`${option.name}_${i}`}
          onChange={() => onToggleRasterRadioGroup(option)}
          disabled={loading}
          checked={
            option.uiGroupName in selectedRasterOptions &&
            option.layerName ===
              selectedRasterOptions[option.uiGroupName].layerName
          }
          name={option.uiGroupName}
          type={option.uiType}
        />
        <OptionValue>{option.name}</OptionValue>
        {option.tooltip && renderTooltip(option)}
      </OptionLabel>
    </div>
  );
  const renderVectorOption = (option: LayerOption, i: number) => (
    <div key={`${i}`}>
      <OptionLabel htmlFor={`${option.name}_${i}`} key={i}>
        <input
          id={`${option.name}_${i}`}
          onChange={(event) => onToggleVector(option, event.target.checked)}
          disabled={loading}
          checked={selectedVectorOptionNames.includes(option.layerName)}
          name={option.uiGroupName}
          type={option.uiType}
        />
        <OptionValue>{option.name}</OptionValue>
        {option.tooltip && renderTooltip(option)}
      </OptionLabel>
    </div>
  );

  const sectionTitleTilesetCategoryPairs = [
    ["Base Layer", TilesetCategory.BaseLayer],
    ["Roads", TilesetCategory.Roads],
    ["Social & Environmental", TilesetCategory.EnviroSocial],
    ["Resource", TilesetCategory.Resource],
    ["Other Technical", TilesetCategory.Technical],
  ];

  return (
    <Container>
      {sectionTitleTilesetCategoryPairs.map(
        ([sectionTitle, tilesetCategory], idx) => (
          <React.Fragment key={sectionTitle}>
            <SectionTitle>{sectionTitle}</SectionTitle>
            {rasterOptions &&
              rasterOptions.length > 0 &&
              rasterOptions
                .filter((option) => option.tilesetCategory === tilesetCategory)
                .map(renderRasterOption)}
            {vectorOptions &&
              vectorOptions.length > 0 &&
              vectorOptions
                .filter((option) => option.tilesetCategory === tilesetCategory)
                .map(renderVectorOption)}
            {idx + 1 !== sectionTitleTilesetCategoryPairs.length && <br />}
          </React.Fragment>
        )
      )}
    </Container>
  );
};

export default LayersControl;

import {
  FillLayer,
  GeoJSONSourceRaw,
  LineLayer,
  RasterLayer,
  RasterSource,
  SymbolLayer,
  VectorSource,
} from "mapbox-gl";

export enum TilesetName {
  // Rasters
  WindSpeed100m = "wind-speed-100m",
  WindSpeed150m = "wind-speed-150m",
  MapboxSatellite = "mapbox-satellite",
  LandUse = "land-use",

  // Vectors
  CountryBoundaries = "contour",
  PrimaryRoadContour = "primary-road-contour",
  SecondaryRoadContour = "secondary-road-contour",
  TertiaryRoadContour = "tertiary-road-contour",
  PopulationDensity = "population-density",
  SubstationsProximity = "distance-to-substation",
  SubstationLocations = "substation-locations",
  WDPA = "WDPA",
  SeaPorts = "sea-ports",
  ShippingLanes = "shipping-lanes",
  Bathymetry = "bathymetry",
  TerrainSlopeSeventeenDegMask = "terrain-slope-seventeen-deg-mask",
  MapboxStreets = "mapbox-streets",
  TyphoonTracks = "typhoon-tracks",

  USWindFarms = "us-wind-farms",
  LspExclusion = "lsp-exclusion",
  LspFill = "lsp-fill",
}

export interface TilesetOption {
  tilesetSources: Record<
    string,
    VectorSource | RasterSource | GeoJSONSourceRaw
  >;
  tilesetLayers: (RasterLayer | LineLayer | FillLayer | SymbolLayer)[];
}

export const WindSpeed100mTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.WindSpeed100m]: {
      type: "raster",
      tiles: [
        "https://terracotta.traverse.ai/singleband/continuous/mws/gwa3/250/100/{z}/{x}/{y}.png?colormap=traverse&stretch_range=[0.5,11.5]",
      ],
    },
    [`${TilesetName.WindSpeed100m}_contour`]: {
      type: "vector",
      tiles: [
        "https://tegola.traverse.ai/maps/mws_100m/{z}/{x}/{y}.vector.pbf",
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.WindSpeed100m}_raster`,
      type: "raster",
      source: TilesetName.WindSpeed100m,
      layout: {
        visibility: "none",
      },
    },
    {
      id: `${TilesetName.WindSpeed100m}_contour`,
      type: "line",
      source: `${TilesetName.WindSpeed100m}_contour`,
      "source-layer": "main_layer",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-opacity": [
          "case",
          ["<", ["to-number", ["get", "ELEV_STR"]], 5],
          0,
          1,
        ],
      },
    },
    {
      id: `${TilesetName.WindSpeed100m}_contour_mws`,
      type: "symbol",
      source: `${TilesetName.WindSpeed100m}_contour`,
      "source-layer": "main_layer",
      layout: {
        "text-field": ["concat", ["get", "ELEV_STR"], "m/s"],
        "text-size": 20,
        "text-justify": "auto",
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
        "text-opacity": [
          "case",
          ["<", ["to-number", ["get", "ELEV_STR"]], 5],
          0,
          ["<", ["%", ["round", ["get", "ELEV_STR"]], 10], 1],
          1,
          0,
        ],
      },
    },
  ],
};

export const WindSpeed150mTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.WindSpeed150m]: {
      type: "raster",
      tiles: [
        "https://terracotta.traverse.ai/singleband/continuous/mws/gwa3/250/150/{z}/{x}/{y}.png?colormap=traverse&stretch_range=[0.5,11.5]",
      ],
    },
    [`${TilesetName.WindSpeed150m}_contour`]: {
      type: "vector",
      tiles: [
        "https://tegola.traverse.ai/maps/mws_150m/{z}/{x}/{y}.vector.pbf",
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.WindSpeed150m}_raster`,
      type: "raster",
      source: TilesetName.WindSpeed150m,
      "source-layer": "gwa3_sea_100_wind-speed_150m-2jgqg3",
      layout: {
        visibility: "none",
      },
    },
    {
      id: `${TilesetName.WindSpeed150m}_contour`,
      type: "line",
      source: `${TilesetName.WindSpeed150m}_contour`,
      "source-layer": "main_layer",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-opacity": [
          "case",
          ["<", ["to-number", ["get", "ELEV_STR"]], 5],
          0,
          1,
        ],
      },
    },
    {
      id: `${TilesetName.WindSpeed150m}_contour_mws`,
      type: "symbol",
      source: `${TilesetName.WindSpeed150m}_contour`,
      "source-layer": "main_layer",
      layout: {
        "text-field": ["concat", ["get", "ELEV_STR"], "m/s"],
        "text-size": 20,
        "text-justify": "auto",
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
        "text-opacity": [
          "case",
          ["<", ["to-number", ["get", "ELEV_STR"]], 5],
          0,
          ["<", ["%", ["round", ["get", "ELEV_STR"]], 10], 1],
          1,
          0,
        ],
      },
    },
  ],
};

export const MapboxSatelliteTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.MapboxSatellite]: {
      type: "raster",
      url: "mapbox://mapbox.satellite",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.MapboxSatellite}_raster`,
      type: "raster",
      source: TilesetName.MapboxSatellite,
      layout: {
        visibility: "none",
      },
    },
  ],
};

export const PrimaryRoadContourTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.PrimaryRoadContour]: {
      type: "vector",
      url: "mapbox://thetlinthu.b4timknp",
    },
    [`${TilesetName.MapboxStreets}_primary`]: {
      type: "vector",
      url: "mapbox://mapbox.mapbox-streets-v8",
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.PrimaryRoadContour,
      type: "line",
      source: TilesetName.PrimaryRoadContour,
      "source-layer": "primary_in_km_contours-5yodf0",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": [
          "step",
          ["get", "DIST"],
          "#1a9641",
          10,
          "#0088ff",
          20,
          "#ff9100",
          50,
          "#ff61ff",
          100,
          "#d7191c",
        ],
        "line-width": 3,
      },
    },
    {
      id: `${TilesetName.MapboxStreets}_primary`,
      source: `${TilesetName.MapboxStreets}_primary`,
      "source-layer": "road",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["in", ["get", "class"], ["literal", ["primary"]]],
    },
  ],
};

export const SecondaryRoadContourTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.SecondaryRoadContour]: {
      type: "vector",
      url: "mapbox://thetlinthu.0iebjcla",
    },
    [`${TilesetName.MapboxStreets}_secondary`]: {
      type: "vector",
      url: "mapbox://mapbox.mapbox-streets-v8",
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.SecondaryRoadContour,
      type: "line",
      source: TilesetName.SecondaryRoadContour,
      "source-layer": "secondary_in_km_contours-9ix06d",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": [
          "step",
          ["get", "DIST"],
          "#1a9641",
          10,
          "#0088ff",
          20,
          "#ff9100",
          50,
          "#ff61ff",
          100,
          "#d7191c",
        ],
        "line-width": 3,
      },
    },
    {
      id: `${TilesetName.MapboxStreets}_secondary`,
      source: `${TilesetName.MapboxStreets}_secondary`,
      "source-layer": "road",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: ["in", ["get", "class"], ["literal", ["primary", "secondary"]]],
    },
  ],
};

export const TertiaryRoadContourTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.TertiaryRoadContour]: {
      type: "vector",
      url: "mapbox://thetlinthu.3qekaxg1",
    },
    [`${TilesetName.MapboxStreets}_tertiary`]: {
      type: "vector",
      url: "mapbox://mapbox.mapbox-streets-v8",
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.TertiaryRoadContour,
      type: "line",
      source: TilesetName.TertiaryRoadContour,
      "source-layer": "tertiary_in_km_contours-3te7pw",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": [
          "step",
          ["get", "DIST"],
          "#1a9641",
          10,
          "#0088ff",
          20,
          "#ff9100",
          50,
          "#ff61ff",
          100,
          "#d7191c",
        ],
        "line-width": 3,
      },
    },
    {
      id: `${TilesetName.MapboxStreets}_tertiary`,
      source: `${TilesetName.MapboxStreets}_tertiary`,
      "source-layer": "road",
      type: "line",
      layout: {
        visibility: "none",
      },
      filter: [
        "in",
        ["get", "class"],
        ["literal", ["primary", "secondary", "tertiary"]],
      ],
    },
  ],
};

export const SubstationsProximityTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.SubstationsProximity]: {
      type: "vector",
      tiles: [
        "https://tegola.traverse.ai/maps/substation_proximity_v2/{z}/{x}/{y}.vector.pbf?",
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.SubstationsProximity}_vector`,
      type: "line",
      source: TilesetName.SubstationsProximity,
      "source-layer": "proximity",
      layout: {
        "line-join": "round",
        "line-cap": "round",
        visibility: "none",
      },
      paint: {
        "line-color": [
          "case",
          ["<=", ["get", "DIST"], 10],
          "#1a9850",
          ["<=", ["get", "DIST"], 30],
          "#3288bd",
          ["<=", ["get", "DIST"], 50],
          "#f46d43",
          "#d73027",
        ],
        "line-opacity": [
          "case",
          ["<", ["%", ["round", ["get", "DIST"]], 10], 1],
          1,
          0,
        ],
        "line-width": 3,
      },
    },
    {
      id: `${TilesetName.SubstationsProximity}_distance`,
      type: "symbol",
      source: TilesetName.SubstationsProximity,
      "source-layer": "proximity",
      layout: {
        "text-field": ["concat", ["get", "DIST"], "km"],
        "text-size": 20,
        "text-justify": "auto",
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
        "text-opacity": [
          "case",
          ["<", ["%", ["round", ["get", "DIST"]], 10], 1],
          1,
          0,
        ],
      },
    },
  ],
};

export const SubstationLocationsTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.SubstationLocations]: {
      type: "vector",
      tiles: [
        "https://tegola.traverse.ai/maps/substation_locations_v1/{z}/{x}/{y}.vector.pbf?debug=true",
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.SubstationLocations}_icon`,
      type: "symbol",
      source: TilesetName.SubstationLocations,
      "source-layer": "substation_locations",
      layout: {
        "text-line-height": 1,
        "text-padding": 0,
        "text-anchor": "center",
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-field": "",
        "text-font": ["Font Awesome 5 Pro Solid", "Arial Unicode MS Regular"],
        "text-size": 24,
        visibility: "none",
      },
      paint: {
        "text-color": "#134863",
      },
    },
    {
      id: `${TilesetName.SubstationLocations}_text`,
      type: "symbol",
      source: TilesetName.SubstationLocations,
      "source-layer": "substation_locations",
      layout: {
        "text-field": [
          "format",
          ["case", ["!=", ["get", "name"], ""], ["get", "name"], ["get", "id"]],
          {},
          "\n",
          {},
          [
            "case",
            ["!=", ["get", "operator"], ""],
            ["concat", "[Operator | ", ["get", "operator"], "]\n"],
            "",
          ],
          { "font-scale": 0.7 },
          [
            "case",
            ["!=", ["get", "voltage"], ""],
            ["concat", "[Voltage | ", ["get", "voltage"], "]\n"],
            "",
          ],
          { "font-scale": 0.7 },
          [
            "case",
            ["!=", ["get", "substation_type"], ""],
            [
              "concat",
              "[Type | ",
              ["upcase", ["slice", ["get", "substation_type"], 0, 1]],
              [
                "slice",
                ["get", "substation_type"],
                1,
                ["length", ["get", "substation_type"]],
              ],
              "]",
            ],
            "",
          ],
          { "font-scale": 0.7 },
        ],
        "text-size": 15,
        "text-anchor": "top-left",
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
      },
    },
  ],
};

export const PopulationDensityTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.PopulationDensity]: {
      type: "vector",
      url: "mapbox://thetlinthu.dnzpeszo",
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.PopulationDensity,
      type: "line",
      source: TilesetName.PopulationDensity,
      "source-layer": "population_density_25km_conto-4s8s7o",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": [
          "case",
          ["<", ["get", "DIST"], 0],
          "#d73028",
          ["<", ["get", "DIST"], 50],
          "#1a9850",
          ["<", ["get", "DIST"], 100],
          "#2166ab",
          ["<", ["get", "DIST"], 150],
          "#fdb863",
          ["<", ["get", "DIST"], 200],
          "#542788",
          "#d73027",
        ],
        "line-width": 3,
      },
    },
  ],
};

export const CountryBoundariesTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.CountryBoundaries]: {
      type: "vector",
      url: "mapbox://mapbox.country-boundaries-v1",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.CountryBoundaries}_vector`,
      type: "line",
      source: TilesetName.CountryBoundaries,
      "source-layer": "country_boundaries",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "#fff",
        "line-width": 3,
        "line-dasharray": [6.0, 2.0],
      },
    },
  ],
};

export const WDPATileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.WDPA]: {
      type: "vector",
      tiles: ["https://tegola.traverse.ai/maps/wdpa/{z}/{x}/{y}.vector.pbf"],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.WDPA}_vector`,
      type: "line",
      source: TilesetName.WDPA,
      "source-layer": "main_layer",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "#00ffff",
        "line-width": 3,
      },
    },
    {
      id: `${TilesetName.WDPA}_name`,
      type: "symbol",
      source: TilesetName.WDPA,
      "source-layer": "main_layer",
      layout: {
        "text-field": [
          "concat",
          ["get", "NAME"],
          " \n[",
          ["get", "DESIG_ENG"],
          "]",
        ],
        "text-size": 12,
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
      },
    },
  ],
};

export const BathymetryTileset: TilesetOption = {
  tilesetSources: {
    [`${TilesetName.Bathymetry}_raster`]: {
      type: "raster",
      url: "mapbox://thetlinthu.8iv7w99s",
    },
    [`${TilesetName.Bathymetry}_contour`]: {
      type: "vector",
      url: "mapbox://thetlinthu.66bbegl7",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.Bathymetry}_raster`,
      type: "raster",
      source: `${TilesetName.Bathymetry}_raster`,
      "source-layer": "global_bathy-a2cxxy",
      layout: {
        visibility: "none",
      },
    },
    {
      id: `${TilesetName.Bathymetry}_contour`,
      type: "line",
      source: `${TilesetName.Bathymetry}_contour`,
      minzoom: 7,
      "source-layer": "gebco2020_lt0_gtNeg300_10mInt-25wd55",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": "#ff0000",
        "line-width": 2,
      },
    },
    {
      id: `${TilesetName.Bathymetry}_elev`,
      type: "symbol",
      source: `${TilesetName.Bathymetry}_contour`,
      minzoom: 7,
      "source-layer": "gebco2020_lt0_gtNeg300_10mInt-25wd55",
      layout: {
        "text-field": ["concat", ["get", "ELEV"], "m"],
        "text-size": 18,
        "text-justify": "auto",
        visibility: "none",
      },
      paint: {
        "text-color": "#fff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
      },
    },
  ],
};

export const ShippingLanesTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.ShippingLanes]: {
      type: "vector",
      url: "mapbox://thetlinthu.coi9yb56",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.ShippingLanes}_vector`,
      type: "line",
      source: TilesetName.ShippingLanes,
      "source-layer": "shipping_routes_v2-blveiw",
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": "rgb(209,250,217)",
      },
    },
  ],
};

export const LandUseTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.LandUse]: {
      type: "raster",
      tiles: [
        'https://terracotta.traverse.ai/singleband/categorical/landuse/copernicus/100/0/{z}/{x}/{y}.png?colormap=explicit&explicit_color_map={"0": "282828", "111": "58481f", "113": "70663e", "112": "009900", "114": "00cc00", "115": "4e751f", "116": "007800", "121": "666000", "123": "8d7400", "122": "8db400", "124": "a0dc00", "125": "929900", "126": "648c00", "20": "ffbb22", "30": "ffff4c", "90": "0096a0", "100": "fae6a0", "60": "b4b4b4", "40": "f096ff", "50": "fa0000", "70": "f0f0f0", "80": "0032c8", "200": "000080"}',
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.LandUse}_raster`,
      type: "raster",
      source: TilesetName.LandUse,
      layout: {
        visibility: "none",
      },
    },
  ],
};

export const USWindFarmsTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.USWindFarms]: {
      type: "geojson",
      data: "./uswtdb_v4_1_20210721.geojson",
    },
    [`${TilesetName.USWindFarms}_convex_hull`]: {
      type: "geojson",
      data: "./uswtdb_v4_1_20210721_convex_hulls.geojson",
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.USWindFarms,
      type: "symbol",
      source: TilesetName.USWindFarms,
      layout: {
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-image": "turbine",
        "icon-size": 0.1,
        visibility: "none",
      },
    },
    {
      id: `${TilesetName.USWindFarms}_name`,
      type: "symbol",
      source: `${TilesetName.USWindFarms}_convex_hull`,
      layout: {
        "text-field": ["get", "p_name"],
        "text-size": 12,
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
      },
    },
    {
      id: `${TilesetName.USWindFarms}_convex_hull`,
      type: "line",
      source: `${TilesetName.USWindFarms}_convex_hull`,
      layout: {
        visibility: "none",
      },
      paint: {
        "line-color": ["get", "color"],
        "line-width": 3,
      },
    },
  ],
};

export const SeaPortsTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.SeaPorts]: {
      type: "vector",
      url: "mapbox://thetlinthu.c3mk8nti",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.SeaPorts}_vector`,
      type: "symbol",
      source: TilesetName.SeaPorts,
      "source-layer": "WPI_Shapefile-drel15",
      layout: {
        "text-line-height": 1,
        "text-padding": 0,
        "text-anchor": "center",
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-field": "",
        "text-font": ["Font Awesome 5 Pro Solid", "Arial Unicode MS Regular"],
        "text-size": 24,
        visibility: "none",
      },
      paint: {
        "text-color": "#134863",
      },
    },
    {
      id: `${TilesetName.SeaPorts}_name`,
      type: "symbol",
      minzoom: 6,
      source: TilesetName.SeaPorts,
      "source-layer": "WPI_Shapefile-drel15",
      layout: {
        "text-field": [
          "concat",
          ["get", "PORT_NAME"],
          "\n[Harbor size | ",
          ["get", "HARBORSIZE"],
          "]",
          "\n[Max vessel Size | ",
          ["get", "MAX_VESSEL"],
          "]",
        ],
        "text-size": 15,
        "text-anchor": "top-left",
        visibility: "none",
      },
      paint: {
        "text-color": "#ffffff",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 2,
      },
    },
  ],
};

export const TerrainSlopeSeventeenDegMaskTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.TerrainSlopeSeventeenDegMask]: {
      type: "raster",
      tiles: [
        "https://terracotta.traverse.ai/singleband/continuous/slope/nasadem/30/0/{z}/{x}/{y}.png?colormap=blacks&stretch_range=[17,30]",
      ],
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.TerrainSlopeSeventeenDegMask}`,
      type: "raster",
      source: TilesetName.TerrainSlopeSeventeenDegMask,
      layout: {
        visibility: "none",
      },
    },
  ],
};

export const TyphoonTracksTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.TyphoonTracks]: {
      type: "vector",
      url: "mapbox://thetlinthu.8nga5yzz",
    },
  },
  tilesetLayers: [
    {
      id: `${TilesetName.TyphoonTracks}`,
      type: "line",
      source: TilesetName.TyphoonTracks,
      "source-layer": "cyclones_typhoon_hurricane-759fsj",
      layout: {
        "line-join": "miter",
        "line-cap": "butt",
        "line-miter-limit": 2,
        visibility: "none",
      },
      paint: {
        "line-color": [
          "step",
          ["get", "USA_SSHS"],
          "rgba(0, 0, 0, 0)",
          0,
          "rgba(0, 0, 0, 0)",
          1,
          "rgba(0, 0, 255, 1)",
          2,
          "rgba(0, 255, 0, 1)",
          3,
          "rgba(255, 0, 0, 1)",
        ],
        "line-width": 2,
      },
    },
  ],
};

export const LspExclusionTileset: TilesetOption = {
  tilesetSources: {
    [TilesetName.LspExclusion]: {
      type: "raster",
      tiles: [
        "https://terracotta.traverse.ai/singleband/categorical/exclusion/generated/30/0/{z}/{x}/{y}.png?colormap=blacks&stretch_range=[0,2]",
      ],
    },
  },
  tilesetLayers: [
    {
      id: TilesetName.LspExclusion,
      type: "raster",
      source: TilesetName.LspExclusion,
      layout: {
        visibility: "none",
      },
    },
  ],
};

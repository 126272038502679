import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import { getLspResults } from "../api";
import { LspResult } from "../types";
import { addTurbinesToMap } from "../utils";
import Container from "./Container";
import DataGroup from "./DataGroup";
import SectionTitle from "./SectionTitle";
import StyledButton from "./StyledButton";
import StyledInput from "./StyledInput";

interface Props {
  mapBoxMap: mapboxgl.Map | null;
}

const LIMIT = 100;

const LspResultViewer: React.FC<Props> = ({ mapBoxMap }) => {
  const [shownIndex, setShownIndex] = useState<number | undefined>(undefined);
  const [lspResults, setLspResults] = useState<LspResult[] | undefined>(
    undefined
  );
  const markerPointsRef = useRef<mapboxgl.Marker[]>([]);

  const res =
    shownIndex === undefined || lspResults === undefined
      ? undefined
      : lspResults[shownIndex];

  useEffect(() => {
    (async () => {
      if (
        shownIndex === undefined ||
        lspResults === undefined ||
        lspResults[shownIndex] !== undefined
      ) {
        return;
      }

      const nextLspResults = await getLspResults({
        limit: LIMIT.toString(),
        offset: shownIndex.toString(),
      });
      if (nextLspResults.length === 0) {
        return;
      }

      setLspResults((originalLspResults) => {
        if (originalLspResults !== undefined) {
          for (let i = 0; i < LIMIT; i += 1) {
            // eslint-disable-next-line no-param-reassign
            originalLspResults[shownIndex + i] = nextLspResults[i];
          }
        }
        return originalLspResults;
      });
    })();
  }, [lspResults, shownIndex]);

  useEffect(() => {
    if (mapBoxMap === null) {
      return;
    }

    if (mapBoxMap.getLayer("lsp-result") !== undefined) {
      mapBoxMap.removeLayer("lsp-result");
    }

    for (const marker of markerPointsRef.current) {
      marker.remove();
    }
    markerPointsRef.current = [];

    if (shownIndex === undefined || res === undefined) {
      return;
    }

    const source = `lsp-result-${shownIndex}`;
    if (mapBoxMap.getSource(source) === undefined) {
      mapBoxMap.addSource(source, {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [res.polygon],
          },
          properties: {},
        },
      });
    }

    mapBoxMap.addLayer({
      id: "lsp-result",
      type: "line",
      source,
      layout: {},
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    });

    markerPointsRef.current = addTurbinesToMap(
      mapBoxMap,
      res.points.map(([x, y]) => ({ x, y }))
    );

    mapBoxMap.setCenter(
      res.polygon
        .reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]])
        .map((c) => c / res.polygon.length) as [number, number]
    );
    mapBoxMap.setZoom(11);
  }, [mapBoxMap, shownIndex, res]);

  return (
    <Container>
      <SectionTitle>View LSP Results</SectionTitle>
      {shownIndex !== undefined && (
        <DataGroup>
          <label htmlFor="form-wind-farm-name">Index</label>
          <StyledInput
            value={shownIndex}
            onChange={({ target: { value } }) => setShownIndex(Number(value))}
            type="text"
          />
        </DataGroup>
      )}
      {res !== undefined && (
        <DataGroup>
          <div>Mean wind speed</div>
          <div>{res.mws.toFixed(2)} m/s</div>
        </DataGroup>
      )}
      <StyledButton
        size="small"
        disabled={shownIndex !== undefined}
        onClick={async () => {
          setShownIndex(0);
          setLspResults(
            await getLspResults({ limit: LIMIT.toString(), offset: "0" })
          );
        }}
      >
        Start
      </StyledButton>
      <div style={{ display: "flex" }}>
        <StyledButton
          size="small"
          disabled={[undefined, 0].includes(shownIndex)}
          onClick={() => setShownIndex((i) => (i === undefined ? i : i - 1))}
        >
          Prev
        </StyledButton>
        <StyledButton
          size="small"
          disabled={shownIndex === undefined}
          onClick={() => setShownIndex((i) => (i === undefined ? i : i + 1))}
        >
          Next
        </StyledButton>
      </div>
      <StyledButton
        size="small"
        disabled={shownIndex === undefined}
        onClick={() => {
          setShownIndex(undefined);
          setLspResults(undefined);
        }}
      >
        Stop
      </StyledButton>
    </Container>
  );
};

export default LspResultViewer;

import React from "react";
import styled from "styled-components";

const LayersLegendFloatingContainer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: calc(100vh - 40px);
  overflow: auto;
`;

const LayerLegend = styled.div`
  position: relative;
  float: right;
  width: fit-content;
  margin-top: 20px;
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 12px 12px;
  border-radius: 3px;
`;

const LayerLegendTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const LayerLegendItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LayerLegendItemColorIndicator = styled.div`
  height: 20px;
  width: 20px;
  border: white solid 1px;
  margin: 4px;
`;

interface LayerLegendItemConfig {
  color: string;
  desc: string;
}

export interface LayerLegendConfig {
  title: string;
  items: LayerLegendItemConfig[];
}

interface LayersLegendConfigProps {
  configs: LayerLegendConfig[];
}

const LayersLegend: React.FC<LayersLegendConfigProps> = ({ configs }) => {
  return (
    <LayersLegendFloatingContainer>
      {configs.map((config) => (
        <LayerLegend key={config.title}>
          <LayerLegendTitle>{config.title}</LayerLegendTitle>
          {config.items.map((entry) => (
            <LayerLegendItemContainer key={entry.desc}>
              <LayerLegendItemColorIndicator
                style={{ backgroundColor: entry.color }}
              >
                &nbsp;
              </LayerLegendItemColorIndicator>
              {entry.desc}
            </LayerLegendItemContainer>
          ))}
        </LayerLegend>
      ))}
    </LayersLegendFloatingContainer>
  );
};

export default LayersLegend;

import {
  GeneratedLayoutResultPoint,
  GetLspResultsQueryParams,
  LspResult,
} from "./types";

const API_LOCATION =
  process.env.REACT_APP_API_LOCATION || "https://slg.traverse.ai";

// eslint-disable-next-line
console.log(process.env.REACT_APP_API_LOCATION);

export class ApiError extends Error {
  constructor(message?: string) {
    // https://stackoverflow.com/a/48342359
    super(message);
    const actualProto = new.target.prototype;
    Object.setPrototypeOf(this, actualProto);
  }
}

export interface GeneratedLayoutResult {
  points: GeneratedLayoutResultPoint[];
  message: string;
  turbine_name: string;
  turbine_class_message: string;
  turbine_capacity: number;
  ellipse_base_diameter: number;
  ellipse_major: number;
  ellipse_minor: number;
  ellipse_rotation: number;
  capacity_factor: number;
}

export const runSeedLayoutGenerator = async (
  polygonWkt: string,
  turbineMaxCapacity: number,
  setAdvancedSettings: boolean,
  turbineMW?: number,
  diameter?: number,
  ellipseMajor?: number,
  ellipseMinor?: number,
  ellipseRotation?: number
): Promise<GeneratedLayoutResult> => {
  const response = await fetch(`${API_LOCATION}/v1/seed_layouts/`, {
    method: "POST",
    body: JSON.stringify({
      boundaries: [polygonWkt],
      constraints: [],
      auto_set_turbine_settings: !setAdvancedSettings,
      ellipse_base_diameter: diameter,
      ellipse_major: ellipseMajor,
      ellipse_minor: ellipseMinor,
      ellipse_rotation: ellipseRotation,
      turbine_max_capacity: turbineMaxCapacity,
      turbine_capacity: turbineMW,
    }),
  });
  const json = await response.json();
  if (!response.ok) {
    throw new ApiError(json.detail);
  }
  return json;
};

export const runSeedLayoutGeneratorWithAdvancedHeuristics = async (
  polygonWkt: string,
  turbineMaxCapacity: number,
  setAdvancedSettings: boolean,
  superfuncA: number,
  superfuncB: number,
  superfuncC: number,
  superfuncMultiplier: number,
  slopefuncDropSteepness: number,
  slopefuncHalfPoint: number,
  proximityMultiplier: number,
  proximityPower: number,
  wakeMaxDownwindDistancePerDiameter: number,
  wakeK: number,
  wakeCt: number,
  elevationAreaFactor: number,
  elevationPowerFactor: number,
  elevationOverallFactor: number,
  turbineMW?: number,
  diameter?: number,
  ellipseMajor?: number,
  ellipseMinor?: number,
  ellipseRotation?: number
): Promise<GeneratedLayoutResult> => {
  const response = await fetch(`${API_LOCATION}/v1/seed_layouts/advanced`, {
    method: "POST",
    body: JSON.stringify({
      boundaries: [polygonWkt],
      constraints: [],
      auto_set_turbine_settings: !setAdvancedSettings,
      ellipse_base_diameter: diameter,
      ellipse_major: ellipseMajor,
      ellipse_minor: ellipseMinor,
      ellipse_rotation: ellipseRotation,
      turbine_max_capacity: turbineMaxCapacity,
      turbine_capacity: turbineMW,
      superfunc_a: superfuncA,
      superfunc_b: superfuncB,
      superfunc_c: superfuncC,
      superfunc_multiplier: superfuncMultiplier,
      slopefunc_drop_steepness: slopefuncDropSteepness,
      slopefunc_half_point: slopefuncHalfPoint,
      proximity_multiplier: proximityMultiplier,
      proximity_power: proximityPower,
      wake_max_downwind_distance_per_diameter:
        wakeMaxDownwindDistancePerDiameter,
      wake_k: wakeK,
      wake_ct: wakeCt,
      elevation_area_factor: elevationAreaFactor,
      elevation_power_factor: elevationPowerFactor,
      elevation_overall_factor: elevationOverallFactor,
    }),
  });
  const json = await response.json();
  if (!response.ok) {
    throw new ApiError(json.detail);
  }
  return json;
};

export const sendKmlResults = async (
  polygonWkt: string,
  name: string,
  turbineMaxCapacity: number,
  setAdvancedSettings: boolean,
  message: string,
  email: string,
  points: GeneratedLayoutResultPoint[],
  turbineMW?: number,
  diameter?: number,
  ellipseMajor?: number,
  ellipseMinor?: number,
  ellipseRotation?: number
): Promise<[string, [number, number, number][]]> => {
  const response = await fetch(`${API_LOCATION}/v1/seed_layouts/email`, {
    method: "POST",
    body: JSON.stringify({
      name,
      boundaries: [polygonWkt],
      constraints: [],
      auto_set_turbine_settings: !setAdvancedSettings,
      ellipse_base_diameter: diameter,
      ellipse_major: ellipseMajor,
      ellipse_minor: ellipseMinor,
      ellipse_rotation: ellipseRotation,
      turbine_max_capacity: turbineMaxCapacity,
      turbine_capacity: turbineMW,
      message,
      email,
      points,
    }),
  });
  const json = await response.json();
  if (!response.ok) {
    throw new ApiError(json.detail);
  }
  return json;
};

export const getLspResults = async (
  query: GetLspResultsQueryParams = {}
): Promise<LspResult[]> => {
  const response = await fetch(
    `${API_LOCATION}/v1/seed_layouts/lsp_results?${new URLSearchParams(
      query
    ).toString()}`
  );
  const json = await response.json();
  if (!response.ok) {
    throw new ApiError(json.detail);
  }
  return json;
};

export const getLspResultsByPolygonFid = async (
  polygonFid: number
): Promise<LspResult> => {
  const response = await fetch(
    `${API_LOCATION}/v1/seed_layouts/lsp_results/${polygonFid}`
  );
  const json = await response.json();
  if (!response.ok) {
    throw new ApiError(json.detail);
  }
  return json;
};

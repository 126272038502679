import { LayerOption } from "./components/LayersControl";
import { LayerLegendConfig } from "./components/LayersLegend";
import {
  BathymetryTileset,
  CountryBoundariesTileset,
  LandUseTileset,
  LspExclusionTileset,
  MapboxSatelliteTileset,
  PopulationDensityTileset,
  PrimaryRoadContourTileset,
  SeaPortsTileset,
  SecondaryRoadContourTileset,
  ShippingLanesTileset,
  SubstationLocationsTileset,
  SubstationsProximityTileset,
  TerrainSlopeSeventeenDegMaskTileset,
  TertiaryRoadContourTileset,
  TilesetName,
  TilesetOption,
  TyphoonTracksTileset,
  USWindFarmsTileset,
  WDPATileset,
  WindSpeed100mTileset,
  WindSpeed150mTileset,
} from "./tilesets";
import { TilesetCategory, UiType } from "./types";

export const RASTER_LAYER_OPTIONS: LayerOption[] = [
  {
    name: "Elevation contours",
    layerName: "None",
    tilesetCategory: TilesetCategory.BaseLayer,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-basemap",
  },
  {
    name: "Satellite",
    layerName: TilesetName.MapboxSatellite,
    tilesetCategory: TilesetCategory.BaseLayer,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-basemap",
  },
  {
    name: "None",
    layerName: "None",
    tilesetCategory: TilesetCategory.Resource,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-meanwindspeed",
  },
  {
    name: "Mean windspeed at 100m hub-height",
    layerName: TilesetName.WindSpeed100m,
    tilesetCategory: TilesetCategory.Resource,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-meanwindspeed",
    tooltip: "Meters per second: Windspeed at 100m hub-height",
  },
  {
    name: "Mean windspeed at 150m hub-height",
    layerName: TilesetName.WindSpeed150m,
    tilesetCategory: TilesetCategory.Resource,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-meanwindspeed",
    tooltip: "Meters per second: windspeed at 150m hub-height",
  },
  {
    name: "None",
    layerName: "None",
    tilesetCategory: TilesetCategory.Roads,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-roads",
  },
  {
    name: "Road proximity (Primary)",
    layerName: TilesetName.PrimaryRoadContour,
    tilesetCategory: TilesetCategory.Roads,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-roads",
    tooltip: "Kilometers from the nearest primary [4-lane] roads.",
  },
  {
    name: "Road proximity (Secondary)",
    layerName: TilesetName.SecondaryRoadContour,
    tilesetCategory: TilesetCategory.Roads,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-roads",
    tooltip:
      "Kilometers from the nearest secondary AND primary [3,4 lane] roads.",
  },
  {
    name: "Road proximity (Tertiary)",
    layerName: TilesetName.TertiaryRoadContour,
    tilesetCategory: TilesetCategory.Roads,
    uiType: UiType.Radio,
    uiGroupName: "radio-tilesets-roads",
    tooltip:
      "Kilometers from the nearest tertiary AND secondary AND primary [2,3,4 lane] roads.",
  },
];

export const VECTOR_LAYER_OPTIONS: LayerOption[] = [
  {
    name: "Country boundaries",
    layerName: TilesetName.CountryBoundaries,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip:
      "Dashed white line. Useful when colorful rasters such as windspeed are toggled.",
  },
  {
    name: "Population density",
    layerName: TilesetName.PopulationDensity,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip:
      "Kilometers from the nearest location that has a population density of 3,000 per km2.",
  },
  {
    name: "Substation proximity",
    layerName: TilesetName.SubstationsProximity,
    tilesetCategory: TilesetCategory.Technical,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Kilometers from the nearest (non-distribution) sub-station.",
  },
  {
    name: "Substation locations",
    layerName: TilesetName.SubstationLocations,
    tilesetCategory: TilesetCategory.Technical,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Name and description of substations (non-distribution).",
  },
  // {
  //   name: "High slope zones",
  //   layerName: TilesetName.TerrainSlopeSeventeenDegMask,
  //   tilesetCategory: TilesetCategory.Technical,
  //   uiType: UiType.Checkbox,
  //   uiGroupName: "checkbox-tilesets-general",
  //   tooltip:
  //     "Zones of 17 degrees (31%) slope which is an indicator of difficult terrain for turbine placement.",
  // },
  {
    name: "Protected areas",
    layerName: TilesetName.WDPA,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Zones of well known protected areas.",
  },
  {
    name: "Sea ports",
    layerName: TilesetName.SeaPorts,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Global sea ports.",
  },
  {
    name: "Shipping lanes",
    layerName: TilesetName.ShippingLanes,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Global shipping lanes.",
  },
  {
    name: "Typhoon tracks",
    layerName: TilesetName.TyphoonTracks,
    tilesetCategory: TilesetCategory.Technical,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip:
      "Tracks of historical typhoons color coded by the Saffir-Simpson scale.",
  },
  {
    name: "Bathymetry",
    layerName: TilesetName.Bathymetry,
    tilesetCategory: TilesetCategory.Technical,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
    tooltip: "Global bathymetry of up to 500m water depth.",
  },
  {
    name: "Land use",
    layerName: TilesetName.LandUse,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
  },
  {
    name: "LSP exclusion",
    layerName: TilesetName.LspExclusion,
    tilesetCategory: TilesetCategory.EnviroSocial,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
  },
  {
    name: "US wind farms",
    layerName: TilesetName.USWindFarms,
    tilesetCategory: TilesetCategory.Technical,
    uiType: UiType.Checkbox,
    uiGroupName: "checkbox-tilesets-general",
  },
];

export const LAYER_TILESET_MAPPING: Record<string, TilesetOption> = {
  // THE ORDERING HERE MATTERS.
  // Place the RASTER tiles as first in queue.
  // Place any layers that have OPACITY second in queue. Such as fully or semi-opaque polygons.
  // Place transparent layers such as lines and no-fill polygons last.
  // It will automatically become the lowest layer.
  // We are adding all the layers upon load and setting visible True False
  // when the user toggles the checkbox.

  // Fully colored rasters.
  [TilesetName.MapboxSatellite]: MapboxSatelliteTileset,
  [TilesetName.WindSpeed100m]: WindSpeed100mTileset,
  [TilesetName.WindSpeed150m]: WindSpeed150mTileset,
  [TilesetName.LandUse]: LandUseTileset,
  [TilesetName.LspExclusion]: LspExclusionTileset,

  // Opaque or Semi Opaque vectors
  [TilesetName.TerrainSlopeSeventeenDegMask]:
    TerrainSlopeSeventeenDegMaskTileset,

  // Lines
  [TilesetName.CountryBoundaries]: CountryBoundariesTileset,
  [TilesetName.PopulationDensity]: PopulationDensityTileset,
  [TilesetName.PrimaryRoadContour]: PrimaryRoadContourTileset,
  [TilesetName.SecondaryRoadContour]: SecondaryRoadContourTileset,
  [TilesetName.SubstationsProximity]: SubstationsProximityTileset,
  [TilesetName.SubstationLocations]: SubstationLocationsTileset,
  [TilesetName.TertiaryRoadContour]: TertiaryRoadContourTileset,
  [TilesetName.SeaPorts]: SeaPortsTileset,
  [TilesetName.ShippingLanes]: ShippingLanesTileset,
  [TilesetName.Bathymetry]: BathymetryTileset,
  [TilesetName.WDPA]: WDPATileset,
  [TilesetName.USWindFarms]: USWindFarmsTileset,
  [TilesetName.TyphoonTracks]: TyphoonTracksTileset,

  None: { tilesetSources: {}, tilesetLayers: [] },
};

export const LAYERS_LEGEND_MAPPING: Record<string, LayerLegendConfig> = {
  [TilesetName.WindSpeed100m]: {
    title: "Mean windspeed - 100m height",
    items: [
      { color: "#0000ff", desc: "Less than 6m/s" },
      { color: "#00ff00", desc: "6m/s" },
      { color: "#ffff00", desc: "7m/s" },
      { color: "#ffaa2b", desc: "8m/s" },
      { color: "#ff0000", desc: "10m/s" },
      { color: "#804040", desc: "12m/s" },
    ],
  },
  [TilesetName.WindSpeed150m]: {
    title: "Mean windspeed - 150m height",
    items: [
      { color: "#0000ff", desc: "Less than 6m/s" },
      { color: "#00ff00", desc: "6m/s" },
      { color: "#ffff00", desc: "7m/s" },
      { color: "#ffaa2b", desc: "8m/s" },
      { color: "#ff0000", desc: "10m/s" },
      { color: "#804040", desc: "12m/s" },
    ],
  },
  [TilesetName.PrimaryRoadContour]: {
    title: "Primary road contours",
    items: [
      { color: "#0088ff", desc: "More than 10km" },
      { color: "#ff9100", desc: "More than 20km" },
      { color: "#ff61ff", desc: "More than 50km" },
      { color: "#d7191c", desc: "More than 100km" },
    ],
  },
  [TilesetName.SecondaryRoadContour]: {
    title: "Secondary road contours",
    items: [
      { color: "#0088ff", desc: "More than 10km" },
      { color: "#ff9100", desc: "More than 20km" },
      { color: "#ff61ff", desc: "More than 50km" },
      { color: "#d7191c", desc: "More than 100km" },
    ],
  },
  [TilesetName.TertiaryRoadContour]: {
    title: "Tertiary road contours",
    items: [
      { color: "#0088ff", desc: "More than 10km" },
      { color: "#ff9100", desc: "More than 20km" },
      { color: "#ff61ff", desc: "More than 50km" },
      { color: "#d7191c", desc: "More than 100km" },
    ],
  },
  [TilesetName.PopulationDensity]: {
    title: "Population density",
    items: [
      { color: "#1a9850", desc: "Less than 50km" },
      { color: "#2166ab", desc: "Less than 100km" },
      { color: "#fdb863", desc: "Less than 150km" },
      { color: "#542788", desc: "Less than 200km" },
      { color: "#d73027", desc: "Others" },
    ],
  },
  [TilesetName.SubstationsProximity]: {
    title: "Proximity to substations",
    items: [
      { color: "#1a9850", desc: "Less than 10km" },
      { color: "#3288bd", desc: "Less than 30km" },
      { color: "#f46d43", desc: "Less than 50km" },
      { color: "#d73027", desc: "Others" },
    ],
  },
  [TilesetName.SeaPorts]: {
    title: "Sea ports",
    items: [
      {
        color: "#ffffff",
        desc: "Harbour Size",
      },
      {
        color: "#ffffff00",
        desc: "-  Large [L], Med [M], Small [S], Very Small [V]",
      },
      { color: "#ffffff", desc: "Max Vessel Size: " },
      {
        color: "#ffffff00",
        desc: "-  >500m [L], <500m [M]",
      },
    ],
  },
  [TilesetName.Bathymetry]: {
    title: "Bathymetry",
    items: [
      { color: "#fdfecc", desc: "0 to -30m" },
      { color: "#5dbaa4", desc: "-30m to -60m" },
      { color: "#3e6495", desc: "-60m to -90m" },
      { color: "#281a2c", desc: "-90m to -500m" },
    ],
  },
  [TilesetName.TyphoonTracks]: {
    title: "Saffir-Simpson Category",
    items: [
      { color: "#0000ff", desc: "Category 1" },
      { color: "#00ff00", desc: "Category 2" },
      { color: "#ff0000", desc: "Category 3 and above" },
    ],
  },
  [TilesetName.LandUse]: {
    title: "Land Use",
    items: [
      { color: "#964B00", desc: "Needle Forest" },
      { color: "#00FF00", desc: "Broad Leaf Forest" },
      { color: "#ffbb22", desc: "Shrubs" },
      { color: "#ffff4c", desc: "Herbaceous Vegetation" },
      { color: "#0096a0", desc: "Herbaceous Wetland" },
      { color: "#fae6a0", desc: "Moss Lichen" },
      { color: "#b4b4b4", desc: "Sparse Vegetation" },
      { color: "#f096ff", desc: "Agriculture" },
      { color: "#fa0000", desc: "Urban" },
      { color: "#f0f0f0", desc: "Snow Ice" },
      { color: "#0032c8", desc: "Water" },
    ],
  },
};

export const AREA_THRESHOLD = 1_000_000_000;

export const DEFAULT_TURBINE_CAPACITY = 4.2;
export const MAX_NUMBER_OF_TURBINES = 125;

export const DEFAULT_LNG = 115.981587;
export const DEFAULT_LAT = 9.657057;
export const DEFAULT_ZOOM = 4;

interface LayerConfig {
  column: string;
  lowFill: string;
  highFill: string;
  lowScore: number;
  highScore: number;
}

export const LSP_LAYER_SETTINGS: Record<string, LayerConfig> = {
  windSpeed: {
    column: "WS_SLOPE_SCORE",
    lowFill: "#f7fcf5",
    highFill: "#04588d",
    lowScore: 6,
    highScore: 22,
  },
  protectedArea: {
    column: "protected_areas_score",
    lowFill: "#00441b",
    highFill: "#f7fcf5",
    lowScore: -2,
    highScore: 0,
  },
  landUse: {
    column: "land_use_score",
    lowFill: "#08306b",
    highFill: "#f7fbff",
    lowScore: -5,
    highScore: 0,
  },
  elevation: {
    column: "elevation_score",
    lowFill: "#67000d",
    highFill: "#f7fbff",
    lowScore: -10,
    highScore: 0,
  },
  slope: {
    column: "slope_score",
    lowFill: "#fcfbfd",
    highFill: "#f7fbff",
    lowScore: -10,
    highScore: 0,
  },
  total: {
    column: "total_score",
    lowFill: "#fff5eb",
    highFill: "#7f2704",
    lowScore: -1,
    highScore: 9,
  },
};

export const LSP_SOURCE_LAYERS = {
  output_contours_id_0: "ID 0",
};

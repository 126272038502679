import { Feature } from "geojson";

export enum ApiState {
  READY,
  LOADING,
  FINISHED,
  ERROR,
}

export type SlgForm = {
  setAdvancedSettings: boolean;
  windFarmName: string;
  maxCapacity: string;
  diameter: string;
  ellipseMajor: string;
  ellipseMinor: string;
  ellipseRotation: string;
  mw: string;
  email: string;
  superfuncA: string;
  superfuncB: string;
  superfuncC: string;
  superfuncMultiplier: string;
  slopefuncDropSteepness: string;
  slopefuncHalfPoint: string;
  proximityMultiplier: string;
  proximityPower: string;
  wakeMaxDownwindDistancePerDiameter: string;
  wakeK: string;
  wakeCt: string;
  elevationAreaFactor: string;
  elevationPowerFactor: string;
  elevationOverallFactor: string;
};

export const initialSlgForm = {
  setAdvancedSettings: false,
  windFarmName: "",
  maxCapacity: "",
  diameter: "",
  ellipseMajor: "",
  ellipseMinor: "",
  ellipseRotation: "",
  mw: "",
  email: "",
  superfuncA: "-361.09",
  superfuncB: "9934.9",
  superfuncC: "-29291",
  superfuncMultiplier: "1904",
  slopefuncDropSteepness: "0.4",
  slopefuncHalfPoint: "12",
  proximityMultiplier: "500",
  proximityPower: "1.1",
  wakeMaxDownwindDistancePerDiameter: "60",
  wakeK: "0.075",
  wakeCt: "0.75",
  elevationAreaFactor: "1.25",
  elevationPowerFactor: "1",
  elevationOverallFactor: "40",
};

export interface GeneratedLayoutResultPoint {
  x: number;
  y: number;
  ws: number;
}

export interface Point {
  x: number;
  y: number;
}

export type SlgApiData = {
  sentData: SlgForm;
  sentPolygon: Feature;
  result: GeneratedLayoutResultPoint[];
  message: string;
  averageWindSpeed: number;
  turbineName: string;
  turbineCapacity: number;
  ellipseBaseDiameter: number;
  ellipseMajor: number;
  ellipseMinor: number;
  ellipseRotation: number;
  capacityFactor: number;
};

export enum TilesetCategory {
  BaseLayer = "baselayer",
  EnviroSocial = "envirosocial",
  Resource = "resource",
  Roads = "roads",
  Technical = "technical",
}

export enum UiType {
  Checkbox = "checkbox",
  Radio = "radio",
}

export type LspResult = {
  polygon_fid: number;
  polygon: [number, number][];
  points: [number, number][];
  mws: number;
  ws_slope_score: number;
  protected_areas_score: number;
  land_use_score: number;
  elevation_score: number;
  slope_score: number;
  total_score: number;
};

export type EllipseConfig = {
  ellipseSourceIdNaming: (idx: number) => string;
  ellipseBaseDiameter: number;
  ellipseMajor: number;
  ellipseMinor: number;
  ellipseRotation: number;
};

export type GetLspResultsQueryParams = {
  limit?: string;
  offset?: string;
};

export type ContourPolygonAttributes = {
  polygon_id: string;
  WS_SLOPE_SCORE: string;
  protected_areas_score: string;
  land_use_score: string;
  elevation_score: string;
  slope_score: string;
  total_score: string;
};

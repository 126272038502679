import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { Tooltip } from "antd";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LSP_LAYER_SETTINGS, LSP_SOURCE_LAYERS } from "../constants";
import Container from "./Container";
import SectionTitle from "./SectionTitle";

interface Props {
  loading: boolean;
  mapBoxMap: mapboxgl.Map | null;
}

const OptionGroup = styled.div`
  margin-bottom: 4px;
`;

const Subtitle = styled.h4`
  color: white;
`;

const LspResultShow: React.FC<Props> = ({ mapBoxMap, loading }) => {
  const [typeSelected, setTypeSelected] = useState("total");
  const [layerIds, setLayerIds] = useState<string[]>(["output_contours_id_0"]);
  const [lspLoading, setLspLoading] = useState(false);

  const setNotLoading = () => setLspLoading(false);

  useEffect(() => {
    if (mapBoxMap === null || loading) {
      return;
    }

    setLspLoading(true);
    mapBoxMap.on("idle", setNotLoading);

    if (mapBoxMap.getSource("lspFill") === undefined) {
      mapBoxMap.addSource("lspFill", {
        type: "vector",
        tiles: [
          "https://tegola.traverse.ai/maps/lsp_results/{z}/{x}/{y}.vector.pbf",
        ],
      });
    }

    for (const sourceLayer of Object.keys(LSP_SOURCE_LAYERS)) {
      if (mapBoxMap.getLayer(`lspFill-${sourceLayer}`) !== undefined) {
        mapBoxMap.removeLayer(`lspFill-${sourceLayer}`);
      }
      if (mapBoxMap.getLayer(`lspFill-contour-${sourceLayer}`) !== undefined) {
        mapBoxMap.removeLayer(`lspFill-contour-${sourceLayer}`);
      }
    }

    if (typeSelected !== undefined) {
      const { column, lowFill, highFill, lowScore, highScore } =
        LSP_LAYER_SETTINGS[typeSelected];
      for (const sourceLayer of layerIds) {
        if (mapBoxMap.getLayer(`lspFill-${sourceLayer}`) === undefined) {
          mapBoxMap.addLayer({
            id: `lspFill-${sourceLayer}`,
            type: "fill",
            source: "lspFill",
            "source-layer": sourceLayer,
            paint: {
              "fill-color": [
                "interpolate",
                ["linear"],
                ["to-number", ["get", column]],
                lowScore,
                ["to-color", lowFill],
                highScore,
                ["to-color", highFill],
              ],
            },
          });
        }
        if (
          mapBoxMap.getLayer(`lspFill-contour-${sourceLayer}`) === undefined
        ) {
          mapBoxMap.addLayer({
            id: `lspFill-contour-${sourceLayer}`,
            type: "line",
            source: "lspFill",
            "source-layer": sourceLayer,
            paint: {
              "line-color": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                "#f00",
                "#000",
              ],
              "line-width": [
                "case",
                ["boolean", ["feature-state", "selected"], false],
                3,
                1,
              ],
            },
          });
        }
      }
    }
    // eslint-disable-next-line consistent-return
    return () => {
      mapBoxMap.off("idle", setNotLoading);
    };
  }, [typeSelected, mapBoxMap, layerIds, loading]);

  return (
    <Container>
      <SectionTitle>Show LSP results</SectionTitle>
      <Subtitle>Fill color</Subtitle>
      {[
        ["windSpeed", "Wind speed"],
        ["protectedArea", "Protected area intersection"],
        ["landUse", "Land use intersection"],
        ["elevation", "Elevation"],
        ["slope", "Slope"],
        ["total", "Total"],
      ].map(([key, desc]) => (
        <OptionGroup key={key}>
          <input
            id={key}
            type="radio"
            name="lsp-type"
            checked={(typeSelected as string) === key}
            onChange={() => setTypeSelected(key)}
            disabled={loading || lspLoading}
          />{" "}
          {desc}
        </OptionGroup>
      ))}
      <Subtitle>
        Layer IDs
        <Tooltip
          title="Within a certain ID, the polygons will not overlap"
          placement="right"
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Subtitle>
      {Object.entries(LSP_SOURCE_LAYERS).map(([key, desc]) => (
        <OptionGroup key={key}>
          <input
            id="none"
            type="checkbox"
            name="lsp-id"
            checked={layerIds.includes(key)}
            onChange={({ target: { checked } }) =>
              setLayerIds((ids) =>
                checked ? ids.concat(key) : ids.filter((id) => id !== key)
              )
            }
            disabled={loading || lspLoading}
          />{" "}
          {desc}
        </OptionGroup>
      ))}
    </Container>
  );
};

export default LspResultShow;

import { polygon } from "@turf/helpers";
import mapboxgl from "mapbox-gl";
import { fromLatLon, toLatLon } from "utm";
import { EllipseConfig, GeneratedLayoutResultPoint, Point } from "./types";

const getEllipsePoint = (
  theta: number,
  majorRadius: number,
  minorRadius: number,
  ellipseAngle: number,
  basePointX: number,
  basePointY: number
): [number, number] => {
  const r =
    (majorRadius * minorRadius) /
    Math.hypot(minorRadius * Math.cos(theta), majorRadius * Math.sin(theta));
  const headingTheta = Math.PI / 2 - theta - (ellipseAngle * Math.PI) / 180;
  return [
    basePointX + r * Math.cos(headingTheta),
    basePointY + r * Math.sin(headingTheta),
  ];
};

export const getEllipse = (
  centerLngLat: [number, number],
  baseDiameter: number,
  majorAxis: number,
  minorAxis: number,
  rotation: number,
  numPoints = 100
): [number, number][] => {
  const { easting, northing, zoneNum, zoneLetter } = fromLatLon(
    centerLngLat[1],
    centerLngLat[0]
  );

  return [...Array(numPoints).keys()].map((i) => {
    const { latitude, longitude } = toLatLon(
      ...getEllipsePoint(
        (2 * Math.PI * i) / numPoints,
        (baseDiameter * majorAxis) / 2,
        (baseDiameter * minorAxis) / 2,
        rotation,
        easting,
        northing
      ),
      zoneNum,
      zoneLetter
    );
    return [longitude, latitude];
  });
};

export const getTurbineSvg = (): HTMLElement => {
  const turbineTemplate = document.createElement("template");
  turbineTemplate.innerHTML =
    '<svg viewBox="89.184 60.339 236.083 457.906" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com"><ellipse cx="234.5" cy="469.017" rx="60.497" ry="40.44" transform="rotate(-25.84351 234.50334462 467.01615338)" stroke-width="8" fill="#fff" stroke="#3b3b3b"/><path d="M222.058 211.19h26.704v262.875a1 1 0 01-1 1h-24.704a1 1 0 01-1-1V211.19z" bx:shape="rect 222.058 211.19 26.704 263.875 0 0 1 1 1@89c378f3" fill="#fff" stroke-width="5" stroke-linecap="round" stroke-miterlimit="5" stroke="#3b3b3b"/><path d="M321.907 308.791L270.94 203.509c-3.423-7.85-3.574-16.742-.422-24.705l42.308-103.385c2.589-6.528-2.859-13.41-9.807-12.388-2.438.358-4.628 1.689-6.07 3.687l-65.886 90.453c-5.018 6.941-12.596 11.595-21.056 12.933l-110.55 17.51c-4.504.718-7.788 4.65-7.691 9.209v.133c.104 4.579 3.588 8.371 8.141 8.862l109.54 11.853c8.517.92 16.317 5.196 21.673 11.882l75.234 88.622c2.866 3.574 7.936 4.471 11.853 2.096l.11-.069c3.9-2.366 5.41-7.228 3.59-11.411zm-82.34-102.667c-10.667 0-17.333-11.547-12-20.784 5.333-9.238 18.667-9.238 24 0 1.216 2.106 1.856 4.496 1.856 6.928 0 7.653-6.203 13.856-13.856 13.856z" fill="#fff" stroke-width="5" stroke="#3b3b3b"/></svg>';
  const turbineSvg = turbineTemplate.content.firstChild as HTMLElement;
  turbineSvg.style.maxWidth = "40px";
  return turbineSvg;
};

export const addTurbinesToMap = (
  map: mapboxgl.Map,
  points: (GeneratedLayoutResultPoint | Point)[],
  ellipseConfig?: EllipseConfig,
  showPopup = false
): mapboxgl.Marker[] => {
  const markers = [];

  for (const [idx, point] of points.entries()) {
    const marker = new mapboxgl.Marker({
      element: getTurbineSvg(),
      anchor: "bottom",
      offset: [-5, 8],
    }).setLngLat([point.x, point.y]);

    let popupText = `(${idx + 1})`;
    if ("ws" in point) {
      popupText += `Wind speed: ${point.ws.toFixed(2)} m/s`;
    }

    marker.setPopup(new mapboxgl.Popup().setText(popupText));

    marker.addTo(map);

    if (showPopup) {
      marker.togglePopup();
    }

    markers.push(marker);

    if (ellipseConfig !== undefined) {
      const ellipseSourceId = ellipseConfig.ellipseSourceIdNaming(idx);

      const ellipse = getEllipse(
        [point.x, point.y],
        ellipseConfig.ellipseBaseDiameter,
        ellipseConfig.ellipseMajor,
        ellipseConfig.ellipseMinor,
        ellipseConfig.ellipseRotation
      );

      map.addSource(ellipseSourceId, {
        type: "geojson",
        data: polygon([ellipse.concat([ellipse[0]])]),
      });
      map.addLayer({
        id: ellipseSourceId,
        type: "line",
        source: ellipseSourceId,
        layout: {},
        paint: {
          "line-color": "#fff",
          "line-width": 3,
        },
      });
    }
  }

  return markers;
};

import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { Tooltip } from "antd";
import mapboxgl from "mapbox-gl";
import React, { useState } from "react";
import styled from "styled-components";
import Container from "./Container";
import DataGroup from "./DataGroup";
import SectionTitle from "./SectionTitle";
import StyledButton from "./StyledButton";
import StyledInput from "./StyledInput";

interface Props {
  mapBoxMap: mapboxgl.Map | null;
  loading: boolean;
}

const TooltipElement = styled.div`
  display: inline-block;
  margin-left: 2px;
`;

const SlopeMask: React.FC<Props> = ({ mapBoxMap, loading }) => {
  const [min, setMin] = useState("8");
  const [max, setMax] = useState("30");
  const [currentActiveLayer, setCurrentActiveLayer] = useState<
    string | undefined
  >();

  return (
    <Container>
      <SectionTitle>
        Set slope mask (degrees) (minimum zoom 10)
        <Tooltip
          title="Zones of slope (in degrees) which is an indicator of difficult terrain (Only visible in >=10 zoom)."
          placement="right"
        >
          <TooltipElement>
            <InfoCircleOutlined />
          </TooltipElement>
        </Tooltip>
      </SectionTitle>
      <DataGroup>
        <label htmlFor="form-min">Min</label>
        <StyledInput
          disabled={loading}
          type="number"
          step="0.1"
          min="0"
          max="90"
          value={min}
          onChange={({ target: { value } }) => setMin(value)}
          name="form-min"
          id="form-min"
        />
      </DataGroup>
      <DataGroup>
        <label htmlFor="form-max">Max</label>
        <StyledInput
          disabled={loading}
          type="number"
          step="0.1"
          min="0"
          max="90"
          value={max}
          onChange={({ target: { value } }) => setMax(value)}
          name="form-max"
          id="form-max"
        />
      </DataGroup>
      <StyledButton
        size="small"
        disabled={loading}
        onClick={() => {
          if (currentActiveLayer !== undefined) {
            mapBoxMap?.removeLayer(currentActiveLayer);
            mapBoxMap?.removeSource(currentActiveLayer);
          }

          const name = `slope-mask-${min}-${max}`;
          setCurrentActiveLayer(name);
          mapBoxMap?.addSource(name, {
            type: "raster",
            tiles: [
              `https://terracotta.traverse.ai/singleband/continuous/slope/nasadem/30/0/{z}/{x}/{y}.png?colormap=reds&stretch_range=[${min},${max}]`,
            ],
          });
          mapBoxMap?.addLayer({
            id: name,
            type: "raster",
            source: name,
            minzoom: 9,
          });
        }}
      >
        Set
      </StyledButton>
      <StyledButton
        size="small"
        disabled={loading || currentActiveLayer === undefined}
        onClick={() => {
          if (currentActiveLayer !== undefined) {
            mapBoxMap?.removeLayer(currentActiveLayer);
            mapBoxMap?.removeSource(currentActiveLayer);
          }
          setCurrentActiveLayer(undefined);
        }}
      >
        Remove
      </StyledButton>
    </Container>
  );
};

export default SlopeMask;

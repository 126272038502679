import { InfoCircleOutlined } from "@ant-design/icons/lib";
import { Tooltip } from "antd";
import { saveAs } from "file-saver";
import mapboxgl from "mapbox-gl";
import React, { useEffect } from "react";
import styled from "styled-components";
import { LspResult } from "../types";
import Container from "./Container";
import SectionTitle from "./SectionTitle";
import StyledButton from "./StyledButton";
import StyledInput from "./StyledInput";

interface Props {
  lspPolygonId?: number;
  lspSelectedData?: LspResult;
  setLspPolygonId: (newId: number | undefined) => void;
  mapBoxMap: mapboxgl.Map | null;
}

const PolygonIdStyledInput = styled(StyledInput)`
  width: 100px;
`;

/* eslint-disable */
// https://stackoverflow.com/a/47593316, modified Mulberry32
const getRandomNumber = (
  seed: number,
  lowerBound: number,
  upperBound: number
): number => {
  let t = (seed += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  const res = ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  return lowerBound + res * (upperBound - lowerBound);
};
/* eslint-enable */

const LspInfoLine = styled.div<{ strong?: boolean; gutterBottom?: boolean }>`
  display: flex;
  gap: 4px;
  font-weight: ${({ strong }) => (strong ? "bold" : "inherit")};
  margin-bottom: ${({ gutterBottom }) => (gutterBottom ? "8px" : "0px")};
`;

const LspInfoKey = styled.div`
  flex: 1;
`;

const PROTECTED_AREA_TOOLTIP: Record<string, string> = {
  "-2.0": "Polygon intersects protected area by 0.5% to 5%",
};

const LAND_USE_TOOLTIP: Record<string, string> = {
  "-5.0":
    "Polygon intersects agriculture areas by > 75%, and urban areas by 0.5% to 10%",
  "-4.0":
    "Polygon intersects agriculture areas by 50% to 75%, and urban areas by 0.5% to 10%",
  "-3.0":
    "Polygon intersects agriculture areas by > 75%; or polygon intersects agriculture areas by 25% to 50%, and urban areas by 0.5% to 10%",
  "-2.0":
    "Polygon intersects agriculture areas by 50% to 75%; or polygon intersects urban areas by 0.5% to 10%",
  "-1.0": "Polygon intersects agriculture areas by 25% to 50%",
};

const ELEVATION_TOOLTIP: Record<string, string> = {
  "-10.0": "Elevation value + hub height is between 2000 and 3000 meters",
  "-5.0": "Elevation value + hub height is between 1000 and 2000 meters",
};

const SLOPE_TOOLTIP: Record<string, string> = {
  "-10.0": "Polygon intersects area with slope > 17 degrees by > 50%",
  "-8.0": "Polygon intersects area with slope > 17 degrees by 40% to 50%",
  "-6.0": "Polygon intersects area with slope > 17 degrees by 30% to 40%",
  "-4.0": "Polygon intersects area with slope > 17 degrees by 20% to 30%",
  "-2.0": "Polygon intersects area with slope > 17 degrees by 10% to 20%",
};

const LspInfoTooltip: React.FC<{ text?: string }> = ({ text }) =>
  text === undefined ? null : (
    <Tooltip title={text} placement="right">
      <InfoCircleOutlined />
    </Tooltip>
  );

const LspInfo: React.FC<Props> = ({
  lspPolygonId,
  setLspPolygonId,
  lspSelectedData,
  mapBoxMap,
}) => {
  useEffect(() => {
    if (lspSelectedData === undefined || mapBoxMap === null) {
      return;
    }

    mapBoxMap
      .setCenter(
        lspSelectedData.polygon
          .reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]])
          .map((c) => c / lspSelectedData.polygon.length) as [number, number]
      )
      .setZoom(11);
  }, [lspSelectedData, mapBoxMap]);

  const kml =
    lspSelectedData !== undefined &&
    `
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:gx="http://www.google.com/kml/ext/2.2">
    <Document>
      <name>${lspPolygonId}</name>
      <Placemark>
        <name>Polygon</name>
        <Polygon>
          <outerBoundaryIs>
            <LinearRing>
              <coordinates>
                ${lspSelectedData.polygon
                  .map(([lon, lat]) => `${lon},${lat}`)
                  .join(" ")}
              </coordinates>
            </LinearRing>
          </outerBoundaryIs>
        </Polygon>
      </Placemark>
      <Folder>
        <name>Turbines</name>
        ${lspSelectedData.points
          .map(
            ([lon, lat], idx) => `
        <Placemark>
          <name>Turbine ${idx}</name>
          <Point>
            <coordinates>${lon},${lat}</coordinates>
          </Point>
        </Placemark>
        `
          )
          .join("")}
      </Folder>
    </Document>
  </kml>
`;

  return (
    <Container>
      <SectionTitle>Large Scale Prospecting Polygon Information</SectionTitle>
      <LspInfoLine gutterBottom>
        <LspInfoKey>Polygon ID</LspInfoKey>
        <PolygonIdStyledInput
          value={lspPolygonId ?? ""}
          onChange={({ target: { value } }) =>
            setLspPolygonId(value === "" ? undefined : Number(value))
          }
          type="text"
        />
      </LspInfoLine>
      {lspPolygonId !== undefined && (
        <>
          <LspInfoLine>
            <LspInfoKey>Wind speed and slope score</LspInfoKey>
            <div>{lspSelectedData?.ws_slope_score ?? "Loading..."}</div>
            <LspInfoTooltip text="The average wind speed of the polygon" />
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Protected areas score</LspInfoKey>
            <div>{lspSelectedData?.protected_areas_score ?? "Loading..."}</div>
            {lspSelectedData !== undefined && (
              <LspInfoTooltip
                text={
                  PROTECTED_AREA_TOOLTIP[lspSelectedData.protected_areas_score]
                }
              />
            )}
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Land use score</LspInfoKey>
            <div>{lspSelectedData?.land_use_score ?? "Loading..."}</div>
            {lspSelectedData !== undefined && (
              <LspInfoTooltip
                text={LAND_USE_TOOLTIP[lspSelectedData.land_use_score]}
              />
            )}
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Elevation score</LspInfoKey>
            <div>{lspSelectedData?.elevation_score ?? "Loading..."}</div>
            {lspSelectedData !== undefined && (
              <LspInfoTooltip
                text={ELEVATION_TOOLTIP[lspSelectedData.elevation_score]}
              />
            )}
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Slope score</LspInfoKey>
            <div>{lspSelectedData?.slope_score ?? "Loading..."}</div>
            {lspSelectedData !== undefined && (
              <LspInfoTooltip
                text={SLOPE_TOOLTIP[lspSelectedData.slope_score]}
              />
            )}
          </LspInfoLine>
          <LspInfoLine strong gutterBottom>
            <LspInfoKey>Total score</LspInfoKey>
            <div>{lspSelectedData?.total_score ?? "Loading..."}</div>
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Mean wind speed</LspInfoKey>
            <div>
              {lspSelectedData === undefined
                ? "Loading..."
                : `${lspSelectedData.mws.toFixed(2)} m/s`}
            </div>
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>Capacity factor</LspInfoKey>
            <div>
              {lspPolygonId === undefined
                ? "Loading..."
                : `${getRandomNumber(lspPolygonId, 15, 30).toFixed(1)}%`}
            </div>
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>CAPEX</LspInfoKey>
            <div>
              {lspPolygonId === undefined
                ? "Loading..."
                : `${(
                    Math.trunc(getRandomNumber(lspPolygonId, 8000, 15000)) * 100
                  ).toFixed(0)} $/MW`}
            </div>
          </LspInfoLine>
          <LspInfoLine>
            <LspInfoKey>IRR</LspInfoKey>
            <div>
              {lspPolygonId === undefined
                ? "Loading..."
                : `${getRandomNumber(lspPolygonId, 5, 12).toFixed(1)}%`}
            </div>
          </LspInfoLine>
          <StyledButton
            size="small"
            onClick={() =>
              kml &&
              saveAs(
                new Blob([kml], {
                  type: "application/vnd.google-earth.kml+xml",
                }),
                "result.kml"
              )
            }
            disabled={!kml}
          >
            Download KML
          </StyledButton>
        </>
      )}
    </Container>
  );
};

export default LspInfo;

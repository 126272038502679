import React from "react";
import styled from "styled-components";

const LocationIndicatorContainer = styled.div`
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  z-index: 1;
  position: absolute;
  bottom: 24px;
  left: 0;
  margin: 12px;
  border-radius: 3px;
`;

const LocationIndicator: React.FC<{
  lng: number;
  lat: number;
  zoom: number;
}> = ({ lng, lat, zoom }) => {
  return (
    <LocationIndicatorContainer>
      Longitude: {lng.toFixed(6)} | Latitude: {lat.toFixed(6)} | Zoom:{" "}
      {zoom.toFixed(1)}
    </LocationIndicatorContainer>
  );
};

export default LocationIndicator;
